import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Stack,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { useAvailabilityState } from "../../../store/context/availability-context";
import capitalize from "../../../utils/capitalize";
import AddNewBooking from "./AddNewBooking";
import Booking from "./Booking";
import Order from "./Order";

const Dashboard = ({
  availableBookings,
  availableOrders,
  availableDays,
  availableOrderDays,
  selectedType,
  selectedPeriod,
}) => {
  const { data: availability } = useAvailabilityState();
  const { excludedPeriods } = availability || {};

  return (
    <Box bgColor="#FEFEFE" minH="calc(100vh - 184px - 2rem)">
      <Container maxW="1720px" my={8}>
        <Flex my={4} justifyContent="flex-end">
          <AddNewBooking />
        </Flex>
        {+selectedType === 2 &&
          availableDays?.map((day, i) => {
            const shorthand = dayjs(day).format("DD.MM");
            const longhand = dayjs(day).format("dddd");

            let excludedPeriodList = [];
            if (excludedPeriods?.length > 0) {
              excludedPeriods.forEach((period) => {
                if (dayjs(day).isSame(period.from, "day"))
                  excludedPeriodList.push(period);
              });
            }
            return (
              <Stack key={i}>
                <Heading>
                  {shorthand} {capitalize(longhand)}
                </Heading>
                <Divider />
                {excludedPeriodList.length > 0 && (
                  <Stack flexWrap="wrap" direction="row">
                    {excludedPeriodList.map(({ from, to }, i) => {
                      const shorthandFrom = dayjs(from).format("HH:mm");
                      const shorthandTo = dayjs(to).format("HH:mm");
                      return (
                        <Tag
                          key={i}
                          borderRadius="full"
                          colorScheme="cyan"
                          mb="4px"
                        >
                          <TagLabel>
                            {shorthandFrom} - {shorthandTo} | Täis
                          </TagLabel>
                        </Tag>
                      );
                    })}
                  </Stack>
                )}
                {excludedPeriodList.length <= 0 && <Box h={4} />}
                <Flex display="inline-flex" flexWrap="wrap">
                  {availableBookings?.map((booking) => {
                    const bookingDay = dayjs(booking.date).day();
                    if (dayjs(booking.date) < dayjs()) {
                      if (day === dayjs(booking.date).format("YYYY-MM-DD")) {
                        return <Booking key={booking._id} booking={booking} />;
                      }
                    } else {
                      if (bookingDay !== dayjs(day).day()) return null;
                      if (day === dayjs(booking.date).format("YYYY-MM-DD")) {
                        return <Booking key={booking._id} booking={booking} />;
                      }
                    }
                  })}
                  {+selectedType === 1 &&
                    availableOrders?.map((order) => {
                      const orderDay = dayjs(order.date).day();
                      if (orderDay !== dayjs(day).day()) return null;
                      return <Order key={order._id} order={order} />;
                    })}
                </Flex>
                <Box h={4} />
              </Stack>
            );
          })}
        {+selectedType === 1 &&
          availableOrderDays
            ?.map((day, i) => {
              const shorthand = dayjs(day).format("DD.MM");
              const longhand = dayjs(day).format("dddd");
              return (
                <Stack key={i}>
                  <Heading>
                    {shorthand} {capitalize(longhand)}
                  </Heading>
                  <Divider />
                  <Flex display="inline-flex" flexWrap="wrap">
                    {selectedPeriod !== 4
                      ? availableOrders?.map((order) => {
                          const orderDay = dayjs(order.date);

                          if (!dayjs(day).isSame(orderDay, "day")) return null;
                          return <Order key={order._id} order={order} />;
                        })
                      : availableOrders
                          .slice(0)
                          .reverse()
                          .map((order) => {
                            const orderDay = dayjs(order.date);
                            if (!dayjs(day).isSame(orderDay, "day"))
                              return null;
                            return <Order key={order._id} order={order} />;
                          })}
                  </Flex>
                  <Box h={4} />
                </Stack>
              );
            })
            [selectedPeriod === 4 ? "reverse" : "slice"]()}
      </Container>
    </Box>
  );
};

export default Dashboard;
