import useGetAvailability from "../utils/useGetAvailability";
import useGetBookings from "../utils/useGetBookings";
import useGetOrders from "../utils/useGetOrders";

const DataWrapper = ({ children }) => {
  useGetBookings();
  useGetOrders();
  useGetAvailability();
  return children;
};

export default DataWrapper;
