import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Container,
} from "@chakra-ui/react";
import React from "react";
import Header from "../Header";

const FullScreenError = ({ children }) => {
  return (
    <>
      <Header />
      <Container maxW="1920px">
        <Alert status="error" my={8}>
          <AlertIcon />
          <AlertTitle mr={2}>{children}</AlertTitle>
          <AlertDescription></AlertDescription>
        </Alert>
      </Container>
    </>
  );
};

export default FullScreenError;
