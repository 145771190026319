import dayjs from "dayjs";
import { useCallback, useState } from "react";

const distance = (from, to) => Math.abs(from - to);
const closestTo = (arr, num) => {
  if (arr.length === 0) {
    return num;
  }
  return arr
    .filter((x) => x !== num)
    .reduce(
      (acc, x) => (distance(num, x) < distance(num, acc) ? x : acc),
      arr[0]
    );
};

const findCurrentDateTimeframeInterval = (timeframes, selectedDate) => {
  const selectedDateUnix = dayjs(selectedDate)
    .subtract(1, "hour")
    .subtract(59, "minute");
  const selecedEndTimeframe = dayjs(selectedDate)
    .add(1, "hour")
    .add(59, "minute");
  const startTime = selectedDateUnix.toISOString();
  const endTime = selecedEndTimeframe.toISOString();

  timeframes.map((timeframe) => {
    //  console.log("timeframe.end", timeframe.end);
    if (timeframe.start > startTime) {
      //  console.log("timeframe.start", timeframe.start);
      // console.log("timeframe.end", timeframe.end);
    }
    if (timeframe.start >= startTime && timeframe.end >= endTime) {
      //  console.log("timeframe", timeframe);
    }
  });
  // First we see if the start and end timeframes are an exact match
  const exactMatchInterval = timeframes.find(
    (timeframe) =>
      dayjs(timeframe.start).unix() >= selectedDateUnix &&
      dayjs(timeframe.end).unix() <= dayjs(selecedEndTimeframe).unix()
  );

  //console.log("exactMatchInterval asdasdasdasdads", exactMatchInterval);
  // if (exactMatchInterval) return exactMatchInterval;

  // If we have no exact match, we will loop over the timeframes
  // and find the closest match

  // Create a list of start and end date differences
  const startDifferences = [];
  const endDifferences = [];

  for (const timeframe of timeframes) {
    startDifferences.push(dayjs(timeframe.start).diff(selectedDate, "minute"));
    endDifferences.push(dayjs(timeframe.end).diff(selectedDate, "minute"));
  }

  // Find the exact closest match for start and end differences

  if (startDifferences.length > 0 && endDifferences.length > 0) {
    const lowest = closestTo(startDifferences, 0);
    const highest = closestTo(endDifferences, 0);

    let result;
    for (const timeframe of timeframes) {
      const startDiff = dayjs(timeframe.start).diff(selectedDate, "minute");
      const endDiff = dayjs(timeframe.end).diff(selectedDate, "minute");
      if (startDiff === lowest && endDiff === highest) result = timeframe;
    }
    return result;
  }

  return null;
};

/* const calculateAttendeesLeftByTables = (tablesLeft) => {
  if (tablesLeft <= 0) return 0;
  return tablesLeft * 4;
}; */

const useAttendeeCount = () => {
  const [currentDateAttendeeCount, setCurrentDateAttendeeCount] = useState(0);
  const [maximumAttendeeCount, setMaximumAttendeeCount] = useState(42);

  const calculateCurrentDateAttendeeCount = useCallback(
    (selectedDate, timeframes) => {
      setCurrentDateAttendeeCount(0);
      if (!timeframes || timeframes?.length <= 0) return;
      const selectedDateTimeframes = [];
      for (const timeframe of timeframes) {
        const { start, end } = timeframe;
        if (dayjs(start).isSame(dayjs(selectedDate), "day")) {
          selectedDateTimeframes.push(timeframe);
        }
      }
      if (selectedDateTimeframes?.length <= 0) return;
      // Create an interval for all of the timeframes, to decide which of it belongs to

      const timeframeInterval =
        findCurrentDateTimeframeInterval(timeframes, selectedDate) || {};
      //  console.log("timeframeInterval END", timeframeInterval.end);
      const { attendeesLeft, start, end } = timeframeInterval || {};

      if (
        (dayjs(selectedDate)?.diff(start) <= -7200000 &&
          dayjs(selectedDate)?.diff(end) >= -12600000) ||
        (dayjs(selectedDate)?.diff(start) <= 7200000 &&
          dayjs(selectedDate)?.diff(end) >= 1800000)
      ) {
        setCurrentDateAttendeeCount(0);
        setMaximumAttendeeCount(42);
      } else {
        if (attendeesLeft) {
          const attendeeRemainder = 42 - attendeesLeft || 0;
          setCurrentDateAttendeeCount(attendeeRemainder);
          setMaximumAttendeeCount(attendeeRemainder);
        } else {
          const attendeeRemainder = 42 - attendeesLeft || 0;
          setCurrentDateAttendeeCount(attendeeRemainder);
        }
      }
    },
    []
  );

  return [
    currentDateAttendeeCount,
    setCurrentDateAttendeeCount,
    calculateCurrentDateAttendeeCount,
    maximumAttendeeCount,
  ];
};

export default useAttendeeCount;
