import { Box, Container, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";
import Header from "../Header";
import AddNewBooking from "../Main/Dashboard/AddNewBooking";

const WorkplaceClean = () => {
  return (
    <>
      <Header />
      <Container maxW="1720px" centerContent my={8}>
        <Flex my={4} justifyContent="flex-end" w="100%">
          <AddNewBooking />
        </Flex>
        <Stack>
          <Flex alignItems="center" justifyContent="center" mt="4rem" mb="2rem">
            {emptiness}
          </Flex>
          <Heading textAlign="center">Tööplats puhas, siruta jalga 😎</Heading>
          <Text textAlign="center" color="gray.400">
            või tee kükke, <em>whatever floats your boat</em>
          </Text>
        </Stack>
      </Container>
    </>
  );
};

const emptiness = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="311.191"
    height="381.244"
    viewBox="0 0 311.191 381.244"
  >
    <g id="emptiness" transform="translate(0)">
      <path
        id="Path_27"
        data-name="Path 27"
        d="M237.145,665.75h6.673l3.175-25.738h-9.848Z"
        transform="translate(-108.073 -291.671)"
        fill="#ffb8b8"
      />
      <path
        id="Path_28"
        data-name="Path 28"
        d="M548.14,783.065h13.141a8.384,8.384,0,0,1,8.375,8.375v.272H548.14Z"
        transform="translate(-420.77 -411.165)"
        fill="#2f2e41"
      />
      <path
        id="Path_29"
        data-name="Path 29"
        d="M79.05,645.6l5.72,3.437,15.977-20.426-8.442-5.072Z"
        transform="translate(-36.025 -284.162)"
        fill="#ffb8b8"
      />
      <path
        id="Path_30"
        data-name="Path 30"
        d="M384.371,766.207l4.453-7.412,11.264,6.767a8.384,8.384,0,0,1,2.865,11.492l-.14.233Z"
        transform="translate(-346.136 -400.105)"
        fill="#2f2e41"
      />
      <path
        id="Path_31"
        data-name="Path 31"
        d="M478.963,684.687c-1.346-1.37-4.69-23.88-9.637-58.064-1.721-11.888-3.349-23.138-4.69-31.755-11.481,25.238-30.041,52.539-52.345,76.979l-.129.141-.176-.073a118.262,118.262,0,0,1-11.672-5.264c-.958-.491-2.085-1.069-3.586-1.82l-.083-.064c-.689-.787,5.6-11.223,27.579-46.706,9.576-15.457,19.477-31.44,26.035-42.537l.061-.1.118-.024c15.853-3.306,31.14.575,43.043,10.927l.1.084,0,.128c-.321,13.012-.051,33.445.187,51.472.465,35.2.434,45.479-.662,45.8l-.055.01c-1.607.132-2.941.264-4.17.385a89.638,89.638,0,0,1-9.715.559h-.115Z"
        transform="translate(-351.706 -316.001)"
        fill="#2f2e41"
      />
      <path
        id="Path_32"
        data-name="Path 32"
        d="M482.393,367.083c-2.246-9.275-4.149-19.735.087-28.277,2.939-5.927,13.813-7.923,20.046-5.788s10.74,7.928,12.722,14.245,1.752,13.122.888,19.689C504.672,366.731,493.209,366.511,482.393,367.083Z"
        transform="translate(-389.699 -205.688)"
        fill="#2f2e41"
      />
      <circle
        id="Ellipse_3"
        data-name="Ellipse 3"
        cx="13.368"
        cy="13.368"
        r="13.368"
        transform="translate(98.673 134.657)"
        fill="#ffb8b8"
      />
      <path
        id="Path_33"
        data-name="Path 33"
        d="M489.946,352.352a16.791,16.791,0,1,1,33.561.442C512.065,352.819,500.624,352.843,489.946,352.352Z"
        transform="translate(-394.248 -207.081)"
        fill="#2f2e41"
      />
      <path
        id="Path_34"
        data-name="Path 34"
        d="M531.206,513.072c-2.465-8.41-3.693-14.3-5.111-21.093-1.944-9.318-4.147-19.879-10.216-40.441l-.015-.051.005-.052c1.719-17.833-5.773-36.757-19.55-49.386a4.77,4.77,0,0,0-6.512.33c-7.751,8.7-12.622,16.857-14.892,24.925l-.049.175c3.236,10.412,6.857,20.876,10.361,31l.05.145-.1.118a88.925,88.925,0,0,0-19.935,57.977,116.831,116.831,0,0,1,39.066-6.541,126.573,126.573,0,0,1,26.866,2.889Z"
        transform="translate(-382.984 -237.012)"
        fill="#3f3d56"
      />
      <path
        id="Path_35"
        data-name="Path 35"
        d="M186.941,382.463l19.744,42.985,11.28-11.417Z"
        transform="translate(-85.194 -174.299)"
        opacity="0.1"
      />
      <path
        id="Path_36"
        data-name="Path 36"
        d="M639.509,143.586c.188-24.047-15.5-43.664-35.037-43.817s-35.529,19.218-35.717,43.264c-.17,21.8,12.708,39.961,29.674,43.276l-1.171,7.021,12.966.1-1.062-7.038C626.179,183.344,639.339,165.387,639.509,143.586Z"
        transform="translate(-430.164 -99.768)"
        fill="#6c63ff"
      />
      <path
        id="Path_37"
        data-name="Path 37"
        d="M585.56,177.158l-4.9-.038a79.735,79.735,0,0,1,.217-27.757l4.9.038A61.347,61.347,0,0,0,585.56,177.158Z"
        transform="translate(-435.085 -122.37)"
        fill="#fff"
      />
      <rect
        id="Rectangle_45"
        data-name="Rectangle 45"
        width="14.695"
        height="3.266"
        transform="translate(166.284 86.481) rotate(0.447)"
        fill="#3f3d56"
      />
      <path
        id="Path_38"
        data-name="Path 38"
        d="M764.627,165.9c1.9-23.972-12.342-44.659-31.819-46.206S696,136.33,694.093,160.3c-1.726,21.734,9.822,40.766,26.509,45.284l-1.67,6.919,12.926,1.027-.556-7.1C748.493,204.609,762.9,187.638,764.627,165.9Z"
        transform="translate(-487.207 -108.809)"
        fill="#ff6584"
      />
      <path
        id="Path_39"
        data-name="Path 39"
        d="M710.111,193.559l-4.883-.388a79.733,79.733,0,0,1,2.2-27.671l4.883.388A61.346,61.346,0,0,0,710.111,193.559Z"
        transform="translate(-492.193 -129.724)"
        fill="#fff"
      />
      <rect
        id="Rectangle_46"
        data-name="Rectangle 46"
        width="14.695"
        height="3.266"
        transform="matrix(0.997, 0.079, -0.079, 0.997, 231.424, 96.574)"
        fill="#3f3d56"
      />
      <rect
        id="Rectangle_47"
        data-name="Rectangle 47"
        width="204.722"
        height="1.633"
        transform="matrix(0.522, -0.853, 0.853, 0.522, 131.288, 272.401)"
        fill="#3f3d56"
      />
      <path
        id="Path_40"
        data-name="Path 40"
        d="M817.215,198.932c11.315-21.219,6.51-45.874-10.73-55.067s-40.389.556-51.7,21.776c-10.258,19.238-7.267,41.3,6.229,52.1l-4.293,5.678,11.441,6.1,2.322-6.728C786.973,227.98,806.958,218.17,817.215,198.932Z"
        transform="translate(-512.15 -118.287)"
        fill="#6c63ff"
      />
      <path
        id="Path_41"
        data-name="Path 41"
        d="M765.824,193.216l-4.322-2.3a79.731,79.731,0,0,1,13.06-24.494l4.322,2.3A61.346,61.346,0,0,0,765.824,193.216Z"
        transform="translate(-518.004 -130.142)"
        fill="#fff"
      />
      <rect
        id="Rectangle_48"
        data-name="Rectangle 48"
        width="14.695"
        height="3.266"
        transform="translate(247.135 98.486) rotate(28.067)"
        fill="#3f3d56"
      />
      <rect
        id="Rectangle_49"
        data-name="Rectangle 49"
        width="204.722"
        height="1.633"
        transform="matrix(0.588, -0.809, 0.809, 0.588, 131.429, 269.034)"
        fill="#3f3d56"
      />
      <rect
        id="Rectangle_50"
        data-name="Rectangle 50"
        width="204.722"
        height="1.633"
        transform="translate(138.731 289.621) rotate(-79.929)"
        fill="#3f3d56"
      />
      <path
        id="Path_42"
        data-name="Path 42"
        d="M564.19,548.21a5.473,5.473,0,0,1-4.928-6.793l-16.269-10.659,9.278-4.008L566.3,537.487a5.5,5.5,0,0,1-2.108,10.724Z"
        transform="translate(-418.424 -294.355)"
        fill="#ffb8b8"
      />
      <path
        id="Path_43"
        data-name="Path 43"
        d="M527.607,484.175c-9.729-11.192-19.789-22.766-28.948-34.4l-.059-.078V449.6a299.542,299.542,0,0,1,1.21-30.313,8.7,8.7,0,0,1-2.306-7.123,9.645,9.645,0,0,1,4.235-7.2c4.584-2.9,11.452-1.328,14.7,3.356,3.134,4.522,2.676,11,.285,14.262l-2.648,20.765-.234-.018.052.026.018-.015.034.042.081.041c0,.009-.01.021-.016.038,8.591,10.489,17.473,21.332,26.345,31.712l.2.23-.25.171c-4.157,2.85-8.455,5.8-12.327,8.647l-.205.145Z"
        transform="translate(-397.66 -238.188)"
        fill="#3f3d56"
      />
      <path
        id="Path_44"
        data-name="Path 44"
        d="M522.034,799.321H314.666a.544.544,0,1,1,0-1.089H522.034a.544.544,0,0,1,0,1.089Z"
        transform="translate(-314.122 -418.077)"
        fill="#3f3d56"
      />
      <path
        id="Path_45"
        data-name="Path 45"
        d="M492.715,480.274Z"
        transform="translate(-224.543 -218.874)"
        fill="#f2f2f2"
      />
      <path
        id="Path_46"
        data-name="Path 46"
        d="M828.681,582.968l-.329,1.038L807.844,577.5c1.818,2.4,7.91,9.9,14.24,11.556,7.562,1.975,11.456-.786,12.6-5.148s-.907-8.675-8.469-10.65c-6.41-1.674-15.541,2-18.175,3.161Z"
        transform="translate(-539.124 -315.353)"
        fill="#f2f2f2"
      />
      <path
        id="Path_47"
        data-name="Path 47"
        d="M350.832,578.731h0Z"
        transform="translate(-159.883 -263.743)"
        fill="#f2f2f2"
      />
      <path
        id="Path_48"
        data-name="Path 48"
        d="M661.262,701.188l-1.087.055-1.088-21.487c-1.613,2.544-6.5,10.877-5.836,17.385.8,7.774,4.748,10.455,9.234,9.994s7.808-3.888,7.009-11.663c-.677-6.59-7.318-13.855-9.327-15.916Z"
        transform="translate(-468.643 -363.993)"
        fill="#f2f2f2"
      />
      <path
        id="Path_49"
        data-name="Path 49"
        d="M63.981,131.667Z"
        transform="translate(-29.158 -60.004)"
        fill="#f2f2f2"
      />
      <path
        id="Path_50"
        data-name="Path 50"
        d="M339,202.59l.649-.874,17.28,12.818c-.939-2.862-4.263-11.935-9.712-15.555-6.51-4.325-11.089-2.98-13.585.776s-1.961,8.5,4.549,12.824c5.518,3.666,15.348,3.156,18.216,2.916Z"
        transform="translate(-322.385 -143.753)"
        fill="#f2f2f2"
      />
    </g>
  </svg>
);

export default WorkplaceClean;
