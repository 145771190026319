import { Container, Spinner } from "@chakra-ui/react";
import React from "react";
import Header from "../Header";

const FullScreenLoader = () => {
  return (
    <>
      <Header />
      <Container
        maxW="1920px"
        centerContent
        minH="calc(100vh - 100px)"
        display="flex"
        justifyContent="center"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Container>
    </>
  );
};

export default FullScreenLoader;
