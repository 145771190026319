import dayjs from "dayjs";

const generateAvailableBookings = (bookings, from, to) => {
  if (!bookings || bookings?.length <= 0) return null;

  const result = [];
  for (const booking of bookings) {
    // We skip bookings that are not active
    if (!booking.active) continue;
    // We exclude all bookings that don't fit from [from] to [to]
    const bookingDate = dayjs(booking.date);
    if (bookingDate < dayjs(from) || bookingDate > dayjs(to)) continue;
    // Here we have the filtered bookings
    result.push(booking);
  }
  return result;
};

export default generateAvailableBookings;
