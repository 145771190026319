import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import theme from "./theme";
import { UserProvider } from "./store/context/user-context";
import "dayjs/locale/et";
import dayjs from "dayjs";
dayjs.locale("et");

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider resetCSS theme={extendTheme(theme)}>
      <UserProvider>
        <App />
      </UserProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
