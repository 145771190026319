import dayjs from "dayjs";

const generateAvailableDays = (bookings) => {
  // We will generate an array of sorted available days from bookings
  const result = [];
  for (const booking of bookings) {
    const bookingDay = dayjs(booking.date).format("YYYY-MM-DD");
    if (!result.includes(bookingDay)) result.push(bookingDay);
  }
  return result;
};

export default generateAvailableDays;
