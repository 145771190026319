import dayjs from "dayjs";
import React from "react";
const OrderStateContext = React.createContext();
const OrderDispatchContext = React.createContext();

function orderReducer(state, action) {
  switch (action.type) {
    case "SET_ORDERS": {
      return { ...state, data: action.payload, isFetched: true };
    }
    case "ADD_ORDER": {
      if (state.data) {
        // Enter the order in the correct place in the array
        const newOrders = [...state.data, action.payload];
        const sortedOrders = newOrders.sort(
          (a, b) => dayjs(a.date) - dayjs(b.date)
        );
        return {
          ...state,
          data: sortedOrders,
        };
      }
      return {
        ...state,
        data: [action.payload],
      };
    }
    case "EDIT_ORDER": {
      const newOrderIndex = state.data.findIndex(
        (order) => order._id === action.payload._id
      );
      const newOrders = state.data.map((order, index) => {
        if (index === newOrderIndex) return action.payload;
        return order;
      });
      // Enter the order in the correct place in the array
      const sortedOrders = newOrders.sort(
        (a, b) => dayjs(a.date) - dayjs(b.date)
      );
      return {
        ...state,
        data: sortedOrders,
      };
    }
    case "SET_ODERS_ERROR": {
      return { ...state, isErrorOrder: true, isFetchedOrder: true };
    }
    case "DELETE_ORDER": {
      if (!state?.data) return state;
      return {
        ...state,
        data: state.data.filter(({ _id }) => _id !== action.payload),
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function OrderProvider({ children }) {
  const [state, dispatch] = React.useReducer(orderReducer, {
    data: null,
    isFetchedOrder: false,
    isErrorOrder: false,
  });
  return (
    <OrderStateContext.Provider value={state}>
      <OrderDispatchContext.Provider value={dispatch}>
        {children}
      </OrderDispatchContext.Provider>
    </OrderStateContext.Provider>
  );
}

function useOrderState() {
  const context = React.useContext(OrderStateContext);
  if (context === undefined) {
    throw new Error("useOrderState must be used within a OrderProvider");
  }
  return context;
}

function useOrderDispatch() {
  const context = React.useContext(OrderDispatchContext);
  if (context === undefined) {
    throw new Error("useOrderDispatch must be used within a OrderProvider");
  }
  return context;
}

export { OrderProvider, useOrderState, useOrderDispatch };
