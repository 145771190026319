import { useToast } from "@chakra-ui/react";
import axios from "axios";
import et from "date-fns/locale/et";
import React, { useCallback, useEffect, useState } from "react";
import { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Cookies from "universal-cookie";
import BookingCreator from "./components/BookingCreator";
import DataWrapper from "./components/DataWrapper";
import Login from "./components/Login";
import Main from "./components/Main";
import Settings from "./components/Settings";
import TableLayout from "./components/TableLayout";
import { API_URL } from "./constants";
import "./date-picker.css";
import "./styles.css";
import { AppProvider } from "./store/context/app-context";
import { AvailabilityProvider } from "./store/context/availability-context";
import { BookingProvider } from "./store/context/booking-context";
import { OrderProvider } from "./store/context/order-context";
import { useUserDispatch, useUserState } from "./store/context/user-context";
setDefaultLocale(et);

const App = () => {
  const { data: user } = useUserState();
  const toast = useToast();
  const dispatch = useUserDispatch();
  const [isFetching, setIsFetching] = useState(false);

  const alertSessionExpired = useCallback(
    () =>
      toast({
        position: "top-right",
        title: "Sessioon aegunud.",
        description: "Pead uuesti sisse logima.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      }),
    [toast]
  );

  const alertRestaurantFetchFailed = useCallback(
    (msg) =>
      toast({
        position: "top-right",
        title: "Restorani andmete pärimine nurjus.",
        description: msg,
        status: "warning",
        duration: 5000,
        isClosable: true,
      }),
    [toast]
  );

  // Here we should check if the user has a token, and if they do, fetch the user data
  useEffect(() => {
    if (!user) {
      const cookies = new Cookies(),
        token = cookies.get("token");
      if (token) {
        setIsFetching(true);
        axios({
          url: `${API_URL}/me`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            setIsFetching(false);
            const { success, user } = res.data;
            if (!success) {
              alertSessionExpired();
              return;
            }
            dispatch({
              type: "SET_USER",
              payload: user,
            });
          })
          .catch(() => {
            setIsFetching(false);
            alertSessionExpired();
          });
      }
    } else {
      // If we have a user, but no token
      const cookies = new Cookies(),
        token = cookies.get("token");
      if (!token) {
        alertSessionExpired();
        dispatch({
          type: "SET_USER",
          payload: null,
        });
      } else {
        // Lets fetch the restaurant
        axios({
          url: `${API_URL}/restaurant`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            id: user?.restaurant,
          },
        })
          .then((res) => {
            const { success, restaurant, msg } = res.data;
            if (!success) {
              alertRestaurantFetchFailed(msg);
              return;
            }
            dispatch({
              type: "SET_RESTAURANT",
              payload: restaurant,
            });
          })
          .catch(() => alertRestaurantFetchFailed());
      }
    }
  }, [user, toast, dispatch, alertSessionExpired, alertRestaurantFetchFailed]);

  if (isFetching) return null;
  return (
    <AppProvider>
      <BookingProvider>
        <OrderProvider>
          <AvailabilityProvider>
            <Router>
              {!user ? (
                <Login />
              ) : (
                <DataWrapper>
                  <Switch>
                    <Route path="/seaded">
                      <Settings />
                    </Route>
                    <Route path="/lauaplaan">
                      <BookingCreator />
                      <TableLayout />
                    </Route>
                    <Route path="/">
                      <Main />
                      <BookingCreator />
                    </Route>
                  </Switch>
                </DataWrapper>
              )}
            </Router>
          </AvailabilityProvider>
        </OrderProvider>
      </BookingProvider>
    </AppProvider>
  );
};

export default App;
