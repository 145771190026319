import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import DatePicker from "react-datepicker";
import Cookies from "universal-cookie";
import { API_URL } from "../../constants";
import { useUserState } from "../../store/context/user-context";

const getFromToIntervalDates = (from, to) => {
  const result = [];
  let lastInterval = dayjs(from);
  while (dayjs(lastInterval).unix() <= dayjs(to).unix()) {
    result.push(lastInterval);
    const interval = dayjs(lastInterval).add(30, "minute");
    lastInterval = interval;
  }
  return result;
};

const DateSelector = ({
  selected,
  isInvalid,
  onChangeCallback,
  excludedPeriods,
  alwaysExcludedDates,
}) => {
  const [includedTimes, setIncludedTimes] = useState([]);
  const [excludedTimes, setExcludedTimes] = useState([]);
  const { restaurant } = useUserState();

  const { availability: restaurantAvailability } = restaurant || {};

  const calculateIncludedTimes = useCallback(
    (selectedDate) => {
      const selectedDay = dayjs(selectedDate).day();
      if (restaurantAvailability) {
        // Find the day that is selected
        const availability = restaurantAvailability.find(
          (opening) => opening.open.day === selectedDay
        );

        // Create an array of available times from
        // availability open time and close time
        const includedTimes = [dayjs(availability.open.time)];
        let index = 0;
        while (
          dayjs(includedTimes[index]).unix() <
          dayjs(availability.close.time).unix()
        ) {
          includedTimes.push(dayjs(includedTimes[index]).add(30, "minute"));
          index++;
        }
        setIncludedTimes(includedTimes.map((date) => new Date(date)));
      }
    },
    [restaurantAvailability]
  );

  const calculateExcludedTimes = useCallback(
    (selectedDate) => {
      if (!excludedPeriods || excludedPeriods?.length <= 0) {
        setExcludedTimes([]);
        return;
      }
      const selectedDayExcludedPeriods = [];
      for (const excludedPeriod of excludedPeriods) {
        if (dayjs(excludedPeriod.from).day() === dayjs(selectedDate).day())
          selectedDayExcludedPeriods.push(excludedPeriod);
      }

      if (selectedDayExcludedPeriods?.length <= 0) {
        setExcludedTimes([]);
        return;
      }

      const bookedTimesArray = [];
      const unique = [];

      for (const period of selectedDayExcludedPeriods) {
        const { from, to } = period || {};
        const excludedDateTimes = getFromToIntervalDates(from, to);
        if (excludedDateTimes?.length > 0) {
          bookedTimesArray.push(
            excludedDateTimes.map((date) => new Date(date))
          );
          //  setExcludedTimes(excludedDateTimes.map((date) => new Date(date)));
        }
      }
      function flatten(arr) {
        return arr.reduce(function (a, b) {
          return a.concat(Array.isArray(b) ? flatten(b) : b);
        }, []);
      }

      const bookedTimes = flatten(bookedTimesArray);
      setExcludedTimes(bookedTimes);
    },
    [excludedPeriods]
  );

  const filterExcludedTimes = useCallback(
    (selectedDate) => {
      // If we don't have any excluded periods, return early
      if (!excludedPeriods || excludedPeriods?.length <= 0) {
        setExcludedTimes([]);
        return;
      }
      calculateExcludedTimes(selectedDate);
    },
    [calculateExcludedTimes, excludedPeriods]
  );

  const filterExcludedDates = useCallback(
    (filterDate) => {
      if (!alwaysExcludedDates || alwaysExcludedDates?.length <= 0) return [];
      const filterDay = dayjs(filterDate).day();
      const excludedDays = [];
      alwaysExcludedDates.forEach((date) => {
        excludedDays.push(dayjs(date).day());
      });
      return !excludedDays.includes(filterDay);
    },
    [alwaysExcludedDates]
  );

  return (
    <>
      <FormControl isRequired isInvalid={isInvalid}>
        <FormLabel htmlFor="date">Kuupäev/Kellaaeg</FormLabel>
        <DatePicker
          id="date"
          placeholderText="01.01.1970 | 00:00"
          selected={selected ? new Date(selected) : null}
          onChange={(date) => {
            calculateIncludedTimes(date);
            filterExcludedTimes(date);
            onChangeCallback(date);
          }}
          showTimeSelect
          dateFormat="dd.MM.yyyy | HH:mm"
          minDate={new Date()}
          timeCaption="Kellaaeg"
          className={isInvalid && "error"}
          autoComplete="chrome-off"
          filterDate={filterExcludedDates}
          includeTimes={includedTimes}
          excludeTimes={excludedTimes}
        />
        <FormErrorMessage>{isInvalid?.msg}</FormErrorMessage>
      </FormControl>
    </>
  );
};

export default DateSelector;
