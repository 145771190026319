import { Box, Button, Container, Stack } from "@chakra-ui/react";
import React from "react";

const PeriodSelector = ({ selectedPeriod, onChange }) => {
  return (
    <Container maxWidth="1720px">
      <Stack direction="row" flexWrap="Wrap">
        <Box mr={4} my={4}>
          {buttonOptions.map((button) => {
            const { id, value } = button;
            return (
              <Button
                key={id}
                colorScheme="blue"
                borderWidth="1px"
                variant={selectedPeriod === id ? "solid" : "outline"}
                mr={4}
                mb={3}
                onClick={() => onChange(id)}
              >
                {value}
              </Button>
            );
          })}
        </Box>
      </Stack>
    </Container>
  );
};

const buttonOptions = [
  {
    id: 4,
    value: "Eelnevad",
  },
  {
    id: 0,
    value: "Täna",
  },
  {
    id: 1,
    value: "Homme",
  },
  {
    id: 2,
    value: "Nädal",
  },
  {
    id: 3,
    value: "Tulevik",
  },
];

export default PeriodSelector;
