import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { useEffect } from "react";
import { API_URL } from "../constants";
import { useAvailabilityDispatch } from "../store/context/availability-context";
import { useBookingState } from "../store/context/booking-context";
import { useUserState } from "../store/context/user-context";

const useGetAvailability = () => {
  const { data: bookings } = useBookingState();
  const { restaurant } = useUserState();
  const toast = useToast();
  const dispatch = useAvailabilityDispatch();

  useEffect(() => {
    // Fetch every time the bookings change
    if (restaurant?._id && bookings)
      axios({
        url: `${API_URL}/availability`,
        method: "GET",
        params: {
          restaurantId: restaurant?._id,
        },
      })
        .then((res) => {
          const {
            success,
            alwaysExcludedDates,
            excludedPeriods,
            timeframes,
          } = res.data;
          if (!success) {
            toast({
              position: "top-right",
              title: "Olemasolevate broneeringute pärimine nurjus.",
              description: "Kalender ei pruugi töödata korrektselt.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            return;
          }
          dispatch({
            type: "SET_AVAILABILITY",
            payload: { alwaysExcludedDates, excludedPeriods, timeframes },
          });
        })
        .catch(() =>
          toast({
            position: "top-right",
            title: "Olemasolevate broneeringute pärimine nurjus.",
            description: "Kalender ei pruugi töödata korrektselt.",
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        );
  }, [restaurant, bookings, toast, dispatch]);
};

export default useGetAvailability;
