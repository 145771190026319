import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useBookingState } from "../../store/context/booking-context";
import generateAvailableBookings from "../../utils/generateAvailableBookings";
import generateAvailableOrders from "../../utils/generateAvailableOrders";
import generateAvailableDays from "../../utils/generateAvailableDays";
import generateAvailableOrderDays from "../../utils/generateAvailableOrderDays";
import useGetAvailability from "../../utils/useGetAvailability";
import useGetBookings from "../../utils/useGetBookings";
import useGetOrders from "../../utils/useGetOrders";
import Header from "../Header";
import FullScreenError from "../UI/FullScreenError";
import FullScreenLoader from "../UI/FullScreenLoader";
import WorkplaceClean from "../UI/WorkplaceClean";
import Dashboard from "./Dashboard";
import PeriodSelector from "./PeriodSelector";
import { useOrderState } from "../../store/context/order-context";
import generateAvailableorders from "../../utils/generateAvailableOrders";
import { Box, Button, Container, Stack } from "@chakra-ui/react";

const Main = () => {
  const { data: bookings, isFetched, isError } = useBookingState();
  const { data: orders, isFetchedOrder, isErrorOrder } = useOrderState();
  const [availableBookings, setAvailableBookings] = useState(null);
  const [availableOrders, setAvailableOrders] = useState(null);
  const [availableOrderDays, setAvailableOrderDays] = useState(null);
  const [availableDays, setAvailableDays] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [selectedType, setSelectedType] = useState(1);

  useEffect(() => {
    if (bookings && bookings.length > 0) {
      // Here we generate available days to show

      // If the result of [generateAvailableBookings] is empty, this means
      // we have no available bookings to show

      // Generate available bookings by selected period
      /* 
        [0] => Current day
        [1] => Tomorrow
        [2] => Current week
        [3] => Future past current week
      */
      let from;
      let to;
      if (selectedPeriod === 4) {
        const todayStart = dayjs("2021-01-01");
        const todayEnd = dayjs().endOf("day").subtract(1, "day");
        from = todayStart;
        to = todayEnd;
      } else if (selectedPeriod === 0) {
        const todayStart = dayjs().startOf("day");
        const todayEnd = dayjs().endOf("day");
        from = todayStart;
        to = todayEnd;
      } else if (selectedPeriod === 1) {
        const tomorrow = dayjs().add(1, "day");
        const tomorrowStart = tomorrow.startOf("day");
        const tomorrowEnd = tomorrow.endOf("day");
        from = tomorrowStart;
        to = tomorrowEnd;
      } else if (selectedPeriod === 2) {
        const weekStart = dayjs().startOf("week");
        const weekEnd = dayjs().endOf("week");
        from = weekStart;
        to = weekEnd;
      } else if (selectedPeriod === 3) {
        const nextWeekStart = dayjs().add(1, "week").startOf("week");
        const nextYear = dayjs().add(1, "year");
        from = nextWeekStart;
        to = nextYear;
      }
      const availableBookings = generateAvailableBookings(bookings, from, to);
      setAvailableBookings(availableBookings);

      // If we have available bookings, lets create available days
      if (availableBookings.length > 0) {
        const availableDays = generateAvailableDays(availableBookings);
        setAvailableDays(availableDays);
      } else {
        setAvailableDays([]);
      }
    } else {
      if (isFetched && bookings?.length <= 0) setAvailableBookings([]);
    }

    if (orders && orders.length > 0) {
      let from;
      let to;
      if (selectedPeriod === 4) {
        const todayStart = dayjs("2021-01-01");
        const todayEnd = dayjs().endOf("day").subtract(1, "day");
        from = todayStart;
        to = todayEnd;
      } else if (selectedPeriod === 0) {
        const todayStart = dayjs().startOf("day");
        const todayEnd = dayjs().endOf("day");
        from = todayStart;
        to = todayEnd;
      } else if (selectedPeriod === 1) {
        const tomorrow = dayjs().add(1, "day");
        const tomorrowStart = tomorrow.startOf("day");
        const tomorrowEnd = tomorrow.endOf("day");
        from = tomorrowStart;
        to = tomorrowEnd;
      } else if (selectedPeriod === 2) {
        const weekStart = dayjs().startOf("week");
        const weekEnd = dayjs().endOf("week");
        from = weekStart;
        to = weekEnd;
      } else if (selectedPeriod === 3) {
        const nextWeekStart = dayjs().add(1, "week").startOf("week");
        const nextYear = dayjs().add(1, "year");
        from = nextWeekStart;
        to = nextYear;
      }

      const availableOrders = generateAvailableorders(orders, from, to);

      setAvailableOrders(availableOrders);
      if (availableOrders.length > 0) {
        const availableOrderDays = generateAvailableOrderDays(availableOrders);
        setAvailableOrderDays(availableOrderDays);
      } else {
        setAvailableOrderDays([]);
      }
    }
  }, [bookings, isFetched, selectedPeriod, orders, isFetchedOrder]);

  const handleChangePeriod = (period) => {
    if (selectedPeriod !== period) {
      setSelectedPeriod(period);
    }
  };

  if (
    !isFetched &&
    !isError &&
    !bookings &&
    !availableBookings &&
    !availableOrders &&
    !isFetchedOrder &&
    !isErrorOrder
  )
    return <FullScreenLoader />;
  if (isFetched && isError)
    return <FullScreenError>Broneeringute pärimine nurjus!</FullScreenError>;
  if (
    isFetched &&
    !isError &&
    bookings.length >= 0 &&
    availableBookings?.length <= 0 &&
    !availableDays
  )
    return <WorkplaceClean />;
  return (
    <>
      <Header />
      <PeriodSelector
        selectedPeriod={selectedPeriod}
        onChange={(period) => handleChangePeriod(period)}
      />
      <Container maxWidth="1720px">
        <Stack direction="row" flexWrap="Wrap">
          <Box mr={4} my={4}>
            <Button
              value={1}
              mr={4}
              mb={3}
              onClick={(e) => setSelectedType(e.target.value)}
              variant={+selectedType === 1 ? "solid" : "outline"}
            >
              Tellimused
            </Button>
            <Button
              value={2}
              mr={4}
              mb={3}
              onClick={(e) => setSelectedType(e.target.value)}
              variant={+selectedType === 2 ? "solid" : "outline"}
            >
              Broneeringud
            </Button>
          </Box>
        </Stack>
      </Container>
      <Dashboard
        availableBookings={availableBookings}
        availableOrders={availableOrders}
        availableOrderDays={availableOrderDays}
        availableDays={availableDays}
        selectedType={selectedType}
        selectedPeriod={selectedPeriod}
      />
    </>
  );
};

export default Main;
