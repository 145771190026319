import {
  Box,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Header from "../Header";
import Availability from "./Availability";

const Settings = () => {
  const [fields, setFields] = useState(null);

  const handleNumberInputChange = (target) => {
    setFields((p) => ({ ...p, [target.id]: +target.value }));
  };

  return (
    <>
      <Header />
      <Container maxW="1720px" py={8}>
        <Heading>Seaded</Heading>
        <Divider />
        <Box h={10} />
        <Stack direction={["column", null, "row"]} spacing={[8, 12, 24, 40]}>
          <Box>
            <Heading size="md">Lahtioleku kuupäevad/kellaajad</Heading>
            <Box my={8}>
              <Availability />
            </Box>
          </Box>
          <Box>
            <Heading size="md">Täituvus</Heading>
            <HStack my={8}>
              <form>
                <HStack>
                  <FormControl
                    id="tables"
                    w="150px"
                    isRequired
                    isDisabled
                    isReadOnly
                    onChange={({ target }) => handleNumberInputChange(target)}
                    value={fields?.tables || ""}
                  >
                    <FormLabel>Laudu kokku</FormLabel>
                    <NumberInput
                      id="tables"
                      min={1}
                      defaultValue={7}
                      inputMode="numeric"
                      isDisabled
                    >
                      <NumberInputField placeholder="0" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                  <FormControl
                    id="diners"
                    w="190px"
                    isRequired
                    isDisabled
                    isReadOnly
                    onChange={({ target }) => handleNumberInputChange(target)}
                    value={fields?.diners || ""}
                  >
                    <FormLabel>Einestajaid laua kohta</FormLabel>
                    <NumberInput
                      id="diners"
                      min={1}
                      defaultValue={4}
                      inputMode="numeric"
                      isDisabled
                    >
                      <NumberInputField placeholder="0" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                </HStack>
              </form>
            </HStack>
            <Box h="50px" />
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default Settings;
