import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Spinner,
  Flex,
  Heading,
  useToast,
  Box,
} from "@chakra-ui/react";
import { API_URL } from "../../constants";
import axios from "axios";
import Cookies from "universal-cookie";
import Booking from "../Main/Dashboard/Booking";
import dayjs from "dayjs";

const TableModal = ({ table, isOpen, onClose, onToggleBookingEdit }) => {
  const [bookings, setBookings] = useState(null);
  const toast = useToast();
  const { id, name } = table || {};
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [empty, setEmpty] = useState();

  const handleChangePeriod = (period) => {
    if (selectedPeriod !== period) {
      setSelectedPeriod(period);
    }
  };

  let from;
  let to;

  if (selectedPeriod === 0) {
    const todayStart = dayjs().startOf("day");
    const todayEnd = dayjs().endOf("day");
    from = todayStart;
    to = todayEnd;
  } else if (selectedPeriod === 1) {
    const tomorrow = dayjs().add(1, "day");
    const tomorrowStart = tomorrow.startOf("day");
    const tomorrowEnd = tomorrow.endOf("day");
    from = tomorrowStart;
    to = tomorrowEnd;
  } else if (selectedPeriod === 2) {
    const weekStart = dayjs().startOf("week");
    const weekEnd = dayjs().endOf("week");
    from = weekStart;
    to = weekEnd;
  } else if (selectedPeriod === 3) {
    const nextWeekStart = dayjs().add(1, "week").startOf("week");
    const nextYear = dayjs().add(1, "year");
    from = nextWeekStart;
    to = nextYear;
  }

  useEffect(() => {
    if (id) {
      const cookies = new Cookies(),
        token = cookies.get("token");
      axios({
        url: `${API_URL}/booked-tables`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { id },
      })
        .then((res) => {
          const { success, data } = res.data;
          if (!success) return;
          setBookings(data);
        })
        .catch(() => {
          toast({
            position: "top-right",
            title: "Tekkis viga broneeringute pärimisel",
            description: "Proovi hiljem uuesti.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          onClose();
        });
    }
    return () => setBookings(null);
  }, [id]);

  const handleBookingDelete = (id) => {
    setBookings((prevState) => prevState.filter((e) => e._id !== id));
  };

  if (!table) return null;
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {name}
          <Box my="2">
            {buttonOptions.map((button) => {
              const { id, value } = button;
              return (
                <Button
                  key={id}
                  colorScheme="blue"
                  borderWidth="1px"
                  variant={selectedPeriod === id ? "solid" : "outline"}
                  mr={4}
                  mb={3}
                  onClick={() => handleChangePeriod(id)}
                >
                  {value}
                </Button>
              );
            })}
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!bookings ? (
            <Flex justifyContent="center" alignItems="center" py={8}>
              <Spinner />
            </Flex>
          ) : (
            bookings &&
            bookings.length > 0 && (
              <Flex display="inline-flex" flexWrap="wrap">
                {bookings.map((booking) => {
                  if (
                    booking.date < new Date(to).toISOString() &&
                    booking.date > new Date(from).toISOString()
                  ) {
                    return (
                      <Booking
                        key={booking._id}
                        booking={booking}
                        onToggleBookingEdit={onToggleBookingEdit}
                        onBookingDelete={handleBookingDelete}
                      />
                    );
                  }
                })}
              </Flex>
            )
          )}
          {bookings && bookings?.length <= 0 && (
            <Heading as="h4">Broneeringud puuduvad.</Heading>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Sulge
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const buttonOptions = [
  {
    id: 0,
    value: "Täna",
  },
  {
    id: 1,
    value: "Homme",
  },
  {
    id: 2,
    value: "Nädal",
  },
  {
    id: 3,
    value: "Tulevik",
  },
];

export default TableModal;
