const TABLES = [
  {
    id: 1,
    capacity: 5,
  },
  {
    id: 2,
    capacity: 2,
  },
  {
    id: 3,
    capacity: 7,
  },
  {
    id: 4,
    capacity: 3,
  },
  {
    id: 5,
    capacity: 3,
  },
  {
    id: 6,
    capacity: 4,
  },
  {
    id: 7,
    capacity: 8,
  },
  {
    id: 8,
    capacity: 2,
  },
  {
    id: 9,
    capacity: 2,
  },
];

export default TABLES;
