import {
  FormControl,
  FormHelperText,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TABLES from "./tables";

const AttendeeControl = ({
  value = "",
  isInvalid,
  isDisabled,
  currentDateAttendeeCount,
  tables,
  onChangeCallback,
}) => {
  const [initial, setInitial] = useState(null);
  const [maxValue, setMaxValue] = useState(42);
  const seatsLeft = 42 - currentDateAttendeeCount;
  // console.log("currentDateAttendeeCount", currentDateAttendeeCount);

  // selected tables id-s
  const selectedTables = tables?.map((table) => {
    const tableValue = Object.values(table);
    return tableValue[0];
  });

  const capacityArray = [];

  useEffect(() => {
    if (currentDateAttendeeCount) {
      setInitial(value);
      setMaxValue(value + seatsLeft);
    }
  }, [currentDateAttendeeCount]);

  const selectedTablesCapacity = TABLES.map((laud) => {
    if (selectedTables?.includes(laud.id)) {
      capacityArray.push(laud.capacity);
    } else {
      return;
    }
  });

  const totalCapacity = capacityArray.reduce((a, b) => a + b, 0);

  return (
    <>
      <FormControl
        id="attendees"
        w="150px"
        isRequired
        isInvalid={isInvalid}
        isDisabled={isDisabled}
      >
        <FormLabel>Inimesi</FormLabel>
        <NumberInput
          id="attendees"
          min={1}
          max={maxValue}
          onChange={(val) => onChangeCallback(val)}
          value={value}
        >
          <NumberInputField placeholder="0" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <FormHelperText>({`${currentDateAttendeeCount}/42`})</FormHelperText>
      </FormControl>
    </>
  );
};

export default AttendeeControl;
