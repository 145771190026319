import React from "react";

export default function TableMap({ id, onChildClick }) {
  return (
    <div
      id="tablemap"
      style={{ width: "max-content", margin: "auto", padding: "2rem 0" }}
    >
      <svg width="1048.23" height="1053.347" viewBox="0 0 1048.23 1053.347">
        <g
          id="Group_111"
          data-name="Group 111"
          transform="translate(-349.885 -177.264)"
        >
          <path
            id="Path_191"
            data-name="Path 191"
            d="M13763.967-2247.97h-1046.23v-300.639h282.132v-237.008h764.1Z"
            transform="translate(-12366.852 3395.234)"
            fill="#292929"
            stroke="#737373"
            stroke-width="2"
          />
          <g
            id="Rectangle_156"
            data-name="Rectangle 156"
            transform="translate(380 1137.264)"
            fill="#090909"
            stroke="#707070"
            stroke-width="2"
          >
            <rect width="180" height="20" stroke="none" />
            <rect x="1" y="1" width="178" height="18" fill="none" />
          </g>
          <g
            id="Rectangle_174"
            data-name="Rectangle 174"
            transform="translate(560 1137.264)"
            fill="#090909"
            stroke="#707070"
            stroke-width="2"
          >
            <rect width="180" height="20" stroke="none" />
            <rect x="1" y="1" width="178" height="18" fill="none" />
          </g>
          <g
            id="Rectangle_175"
            data-name="Rectangle 175"
            transform="translate(740 1137.264)"
            fill="#090909"
            stroke="#707070"
            stroke-width="2"
          >
            <rect width="180" height="20" stroke="none" />
            <rect x="1" y="1" width="178" height="18" fill="none" />
          </g>
          <g
            id="Rectangle_158"
            data-name="Rectangle 158"
            transform="translate(1103 1137.264)"
            fill="#090909"
            stroke="#707070"
            stroke-width="2"
          >
            <rect width="124" height="20" stroke="none" />
            <rect x="1" y="1" width="122" height="18" fill="none" />
          </g>
          <g
            id="Rectangle_173"
            data-name="Rectangle 173"
            transform="translate(1225 1137.264)"
            fill="#090909"
            stroke="#707070"
            stroke-width="2"
          >
            <rect width="124" height="20" stroke="none" />
            <rect x="1" y="1" width="122" height="18" fill="none" />
          </g>
          <rect
            id="Rectangle_166"
            data-name="Rectangle 166"
            width="81.264"
            height="169.51"
            transform="translate(1029.51 614.264) rotate(90)"
            fill="#3b3b3b"
          />
          <rect
            id="Rectangle_167"
            data-name="Rectangle 167"
            width="94.119"
            height="185.916"
            transform="translate(1290.377 708.35)"
            fill="#3b3b3b"
          />
          <rect
            id="Rectangle_168"
            data-name="Rectangle 168"
            width="32.809"
            height="288.39"
            transform="translate(1384.496 614.264) rotate(90)"
            fill="#3b3b3b"
          />
          <g
            id="Group_106"
            data-name="Group 106"
            transform="translate(-26 55.058)"
            onClick={() => onChildClick(5)}
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            <g
              id="Rectangle_169"
              data-name="Rectangle 169"
              transform="translate(1109.825 898.578)"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            >
              <rect width="104" height="104" stroke="none" />
              <rect x="1" y="1" width="102" height="102" fill="none" />
            </g>
            <text
              id="_4_kohta"
              data-name="3 kohta"
              transform="translate(1140.325 977.828)"
              fill="#fff"
              font-size="14"
              font-family="Caladea-Italic, Caladea"
              font-style="italic"
            >
              <tspan x="0" y="0">
                3 kohta
              </tspan>
            </text>
            <path
              id="Path_254"
              data-name="Path 254"
              d="M7.544.328A9.759,9.759,0,0,1,2.235-.964,3.848,3.848,0,0,1,.205-4.305,3.3,3.3,0,0,1,1-6.642,2.558,2.558,0,0,1,2.952-7.5a3.163,3.163,0,0,1,1.886.7,4.039,4.039,0,0,0-.656,2.132,2.429,2.429,0,0,0,.943,1.989,4.375,4.375,0,0,0,2.788.759,4.669,4.669,0,0,0,3.034-1.107,7.264,7.264,0,0,0,2.111-3.014,11.388,11.388,0,0,0,.759-4.244q0-4.346-4.182-4.346a12.627,12.627,0,0,0-5.7,1.517l-.943-1.23L6.109-26.9l.82-.574H7.954q3.526.082,5.084.082l5.043-.041.7.574-.738,2.583H8.282l-2.05,8.2a12.789,12.789,0,0,1,4.51-.861,7.1,7.1,0,0,1,5.064,1.681,6.329,6.329,0,0,1,1.783,4.838,11.152,11.152,0,0,1-1.312,5.33,10.427,10.427,0,0,1-3.608,3.936A9.278,9.278,0,0,1,7.544.328Z"
              transform="translate(1152 948)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_107"
            data-name="Group 107"
            onClick={() => onChildClick(6)}
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            <g
              id="Rectangle_176"
              data-name="Rectangle 176"
              transform="translate(1069 764.264)"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            >
              <rect width="96.627" height="96.628" stroke="none" />
              <rect x="1" y="1" width="94.627" height="94.628" fill="none" />
            </g>
            <text
              id="_4_kohta-2"
              data-name="4 kohta"
              transform="translate(1095.814 838.5)"
              fill="#fff"
              font-size="14"
              font-family="Caladea-Italic, Caladea"
              font-style="italic"
            >
              <tspan x="0" y="0">
                4 kohta
              </tspan>
            </text>
            <path
              id="Path_253"
              data-name="Path 253"
              d="M8.159.287A7.163,7.163,0,0,1,2.706-1.661,7.892,7.892,0,0,1,.861-7.3a19.6,19.6,0,0,1,2.214-9.389A20.376,20.376,0,0,1,9.061-23.6a26.042,26.042,0,0,1,8.446-4.08l.533,1.8a23.25,23.25,0,0,0-7.79,4.941,17.577,17.577,0,0,0-4.51,7.4,10.71,10.71,0,0,1,5.412-1.6,7.323,7.323,0,0,1,4.9,1.517,5.54,5.54,0,0,1,1.784,4.469,9.259,9.259,0,0,1-1.292,4.859A9.107,9.107,0,0,1,13.038-.923,10.009,10.009,0,0,1,8.159.287Zm.287-2.009a4.626,4.626,0,0,0,2.911-.984,6.525,6.525,0,0,0,1.989-2.665,9.519,9.519,0,0,0,.717-3.772,4.219,4.219,0,0,0-.922-2.891,3.392,3.392,0,0,0-2.686-1.045A7.751,7.751,0,0,0,5-10.619a30.747,30.747,0,0,0-.287,3.9,6.537,6.537,0,0,0,.9,3.608A3.127,3.127,0,0,0,8.446-1.722Z"
              transform="translate(1108 816)"
              fill="#fff"
            />
          </g>
          <g
            id="Rectangle_179"
            data-name="Rectangle 179"
            transform="translate(626 177.264)"
            fill="#292929"
            stroke="#707070"
            stroke-width="2"
          >
            <rect width="477" height="433" stroke="none" />
            <rect x="1" y="1" width="475" height="431" fill="none" />
          </g>
          <rect
            id="Rectangle_180"
            data-name="Rectangle 180"
            width="66"
            height="52"
            transform="translate(1030 588.264)"
            fill="#292929"
          />
          <line
            id="Line_138"
            data-name="Line 138"
            x1="63.645"
            y2="82.567"
            transform="translate(1006.438 1147.433)"
            fill="none"
            stroke="#707070"
            stroke-width="2"
          />
          <rect
            id="Rectangle_165"
            data-name="Rectangle 165"
            width="105"
            height="232"
            transform="translate(631 614.264)"
            fill="#3b3b3b"
          />
          <rect
            id="Rectangle_186"
            data-name="Rectangle 186"
            width="95"
            height="51"
            transform="translate(765 307.264)"
            fill="#3b3b3b"
          />
          <rect
            id="Rectangle_188"
            data-name="Rectangle 188"
            width="95"
            height="149"
            transform="translate(765 364.264)"
            fill="#3b3b3b"
          />
          <rect
            id="Rectangle_189"
            data-name="Rectangle 189"
            width="95"
            height="51"
            transform="translate(765 519.264)"
            fill="#3b3b3b"
          />
          <g
            id="Group_108"
            data-name="Group 108"
            onClick={() => onChildClick(7)}
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            <g
              id="Rectangle_184"
              data-name="Rectangle 184"
              transform="translate(886 443)"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            >
              <rect width="82.627" height="146.628" stroke="none" />
              <rect x="1" y="1" width="80.627" height="144.628" fill="none" />
            </g>
            <path
              id="Path_252"
              data-name="Path 252"
              d="M5.863.287A2.321,2.321,0,0,1,4.1-.451a2.99,2.99,0,0,1-.7-2.132A10.31,10.31,0,0,1,4.695-7.442a64.154,64.154,0,0,1,4.612-6.991q.779-1.148,2.091-2.87,4.674-6.519,5.125-7.134H6.15a18.689,18.689,0,0,0-1.558,4.387,6.656,6.656,0,0,1-.82.082,5.019,5.019,0,0,1-.779-.082l.451-6.724.656-.615H6.273q1.312.041,5.535.041,6.4,0,7.831-.041l.451,1.763q-.779.943-3.915,5.556t-5.781,9.389Q7.749-5.9,7.749-3.608a2.806,2.806,0,0,0,.943,2.3A3.683,3.683,0,0,1,7.4-.123,3.2,3.2,0,0,1,5.863.287Z"
              transform="translate(918 522)"
              fill="#fff"
            />
            <text
              id="_8_kohta"
              data-name="8 kohta"
              transform="translate(905 574)"
              fill="#fff"
              font-size="14"
              font-family="Caladea-Italic, Caladea"
              font-style="italic"
            >
              <tspan x="0" y="0">
                8 kohta
              </tspan>
            </text>
          </g>
          <g
            id="Group_102"
            data-name="Group 102"
            onClick={() => onChildClick(1)}
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            <g
              id="Group_80"
              data-name="Group 80"
              transform="translate(-30 76.264)"
            >
              <g
                id="Path_218"
                data-name="Path 218"
                transform="translate(424 884.736)"
                fill="none"
              >
                <path d="M0,0H104V104H0Z" stroke="none" />
                <path
                  d="M 1.999992370605469 1.999992370605469 L 1.999992370605469 102.0001220703125 L 102 102.0001220703125 L 102 1.999992370605469 L 1.999992370605469 1.999992370605469 M -7.62939453125e-06 -7.62939453125e-06 L 104 -7.62939453125e-06 L 104 104.0001220703125 L -7.62939453125e-06 104.0001220703125 L -7.62939453125e-06 -7.62939453125e-06 Z"
                  stroke="none"
                  fill="#fff"
                />
              </g>
            </g>
            <path
              id="Path_258"
              data-name="Path 258"
              d="M.41-1.517a29.864,29.864,0,0,0,3.075-.349,10.465,10.465,0,0,0,2.624-.717L9.43-23.493h-6.4l.246-1.763a27.3,27.3,0,0,0,8.979-2.255l1.312.574L9.717-2.583a15.222,15.222,0,0,0,4.838,1.066L14.391,0H.205Z"
              transform="translate(438 1017)"
              fill="#fff"
            />
            <text
              id="_6_kohta"
              data-name="5 kohta"
              transform="translate(424 1050)"
              fill="#fff"
              font-size="14"
              font-family="Caladea-Italic, Caladea"
              font-style="italic"
            >
              <tspan x="0" y="0">
                5 kohta
              </tspan>
            </text>
          </g>
          <g id="Group_103" data-name="Group 103" transform="translate(0 46)">
            <g
              id="Group_101"
              data-name="Group 101"
              transform="translate(136 98.264)"
              onClick={() => onChildClick(2)}
              style={{ pointerEvents: "all", cursor: "pointer" }}
            >
              <g
                id="Path_218-2"
                data-name="Path 218"
                transform="translate(424 884.736)"
                fill="none"
              >
                <path d="M0,0H104V104H0Z" stroke="none" />
                <path
                  d="M 1.999992370605469 1.999992370605469 L 1.999992370605469 102.0001220703125 L 102 102.0001220703125 L 102 1.999992370605469 L 1.999992370605469 1.999992370605469 M -7.62939453125e-06 -7.62939453125e-06 L 104 -7.62939453125e-06 L 104 104.0001220703125 L -7.62939453125e-06 104.0001220703125 L -7.62939453125e-06 -7.62939453125e-06 Z"
                  stroke="none"
                  fill="#fff"
                />
              </g>
            </g>
            <path
              id="Path_257"
              data-name="Path 257"
              d="M16.687,0H-.123L.246-2.337a114.9,114.9,0,0,0,9.082-8.3,26.6,26.6,0,0,0,4.531-5.986,11.52,11.52,0,0,0,1.312-5.228,3.38,3.38,0,0,0-.964-2.562,3.55,3.55,0,0,0-2.562-.922,4.75,4.75,0,0,0-3.28,1.169,3.717,3.717,0,0,0-1.312,2.89,3.243,3.243,0,0,0,.533,1.8,3.34,3.34,0,0,1-2.255.9,2.057,2.057,0,0,1-1.456-.554,2.219,2.219,0,0,1-.595-1.7,5.32,5.32,0,0,1,1.209-3.4,8.274,8.274,0,0,1,3.219-2.4,10.6,10.6,0,0,1,4.305-.881,7.29,7.29,0,0,1,4.961,1.64,5.761,5.761,0,0,1,1.886,4.592,11.625,11.625,0,0,1-1.333,5.269,24.106,24.106,0,0,1-4.448,5.8A83.161,83.161,0,0,1,4.674-2.993H17.1Z"
              transform="translate(604 1039)"
              fill="#fff"
            />
            <text
              id="_3_kohta"
              data-name="2 kohta"
              transform="translate(590 1072)"
              fill="#fff"
              font-size="14"
              font-family="Caladea-Italic, Caladea"
              font-style="italic"
            >
              <tspan x="0" y="0">
                2 kohta
              </tspan>
            </text>
          </g>
          <g id="Group_104" data-name="Group 104">
            <path
              id="Path_256"
              data-name="Path 256"
              d="M7.3-15.129a7.965,7.965,0,0,0,5.351-1.886,6.619,6.619,0,0,0,1.989-5.166,3.09,3.09,0,0,0-.82-2.317,3.21,3.21,0,0,0-2.337-.8,4.9,4.9,0,0,0-3.014.964,3.079,3.079,0,0,0-1.291,2.6,3.885,3.885,0,0,0,.164,1.148,3.1,3.1,0,0,1-2.009.738,1.652,1.652,0,0,1-1.251-.492,2.059,2.059,0,0,1-.471-1.476,4.209,4.209,0,0,1,1.271-3.055,8.227,8.227,0,0,1,3.2-1.968,11.685,11.685,0,0,1,3.9-.677,7.386,7.386,0,0,1,4.674,1.435,4.843,4.843,0,0,1,1.845,4.059A7.817,7.817,0,0,1,17.1-17.569a9.452,9.452,0,0,1-4.961,3.3q4.592,1.23,4.592,5.453a8.791,8.791,0,0,1-1.271,4.695A8.925,8.925,0,0,1,12.013-.882,10.05,10.05,0,0,1,7.175.287,10.685,10.685,0,0,1,1.948-.861,3.772,3.772,0,0,1-.123-4.387,3.141,3.141,0,0,1,.656-6.642a2.552,2.552,0,0,1,1.927-.82,2.934,2.934,0,0,1,1.886.656,5.234,5.234,0,0,0-.656,2.255A2.284,2.284,0,0,0,4.8-2.6a4.834,4.834,0,0,0,2.87.717,4.622,4.622,0,0,0,3.915-1.968,7.708,7.708,0,0,0,1.456-4.715,4.3,4.3,0,0,0-1.517-3.485,6.88,6.88,0,0,0-4.51-1.271Z"
              transform="translate(791 1039)"
              fill="#fff"
            />
            <g
              id="Group_81"
              data-name="Group 81"
              transform="translate(-30 76.264)"
              onClick={() => onChildClick(3)}
              style={{ pointerEvents: "all", cursor: "pointer" }}
            >
              <g
                id="Ellipse_25"
                data-name="Ellipse 25"
                transform="translate(762 888)"
                fill="none"
                stroke="#fff"
                stroke-width="2"
              >
                <circle cx="68" cy="68" r="68" stroke="none" />
                <circle cx="68" cy="68" r="67" fill="none" />
              </g>
            </g>
            <text
              id="_7_kohta"
              data-name="7 kohta"
              transform="translate(777 1075)"
              fill="#fff"
              font-size="14"
              font-family="Caladea-Italic, Caladea"
              font-style="italic"
            >
              <tspan x="0" y="0">
                7 kohta
              </tspan>
            </text>
          </g>
          <g
            id="Group_105"
            data-name="Group 105"
            onClick={() => onChildClick(4)}
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            <g
              id="Rectangle_164"
              data-name="Rectangle 164"
              transform="translate(1239.044 974.078)"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            >
              <rect width="102.666" height="102.666" stroke="none" />
              <rect x="1" y="1" width="100.666" height="100.666" fill="none" />
            </g>
            <text
              id="_6_kohta-2"
              data-name="3 kohta"
              transform="translate(1267 1059.5)"
              fill="#fff"
              font-size="14"
              font-family="Caladea-Italic, Caladea"
              font-style="italic"
            >
              <tspan x="0" y="0">
                3 kohta
              </tspan>
            </text>
            <path
              id="Path_255"
              data-name="Path 255"
              d="M.451-7.5.369-8.856l13-19.024a4.409,4.409,0,0,1,3.239.943L4.1-9.758h8.077l1.312-7.995a4.457,4.457,0,0,0,1.865-.492,2.456,2.456,0,0,0,1.21-1.23h.779L15.785-9.758a15.826,15.826,0,0,0,2.091.472q1.148.184,1.476.225L19.106-7.5h-3.69L14.268,0H10.66l1.148-7.5Z"
              transform="translate(1280 1032)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_109"
            data-name="Group 109"
            onClick={() => onChildClick(8)}
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            <g
              id="Rectangle_185"
              data-name="Rectangle 185"
              transform="translate(886 333)"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            >
              <rect width="82.627" height="82.628" stroke="none" />
              <rect x="1" y="1" width="80.627" height="80.628" fill="none" />
            </g>
            <text
              id="_2_kohta"
              data-name="2 kohta"
              transform="translate(905 404)"
              fill="#fff"
              font-size="14"
              font-family="Caladea-Italic, Caladea"
              font-style="italic"
            >
              <tspan x="0" y="0">
                2 kohta
              </tspan>
            </text>
            <path
              id="Path_250"
              data-name="Path 250"
              d="M8.364.287a9.51,9.51,0,0,1-5.7-1.5A5.068,5.068,0,0,1,.615-5.576a7.93,7.93,0,0,1,1.907-5.392,15.566,15.566,0,0,1,4.2-3.465A6.749,6.749,0,0,1,4.592-16.4a4.922,4.922,0,0,1-.779-2.829A7.9,7.9,0,0,1,5-23.534a8.129,8.129,0,0,1,3.219-2.931,9.746,9.746,0,0,1,4.53-1.045,7.211,7.211,0,0,1,4.756,1.435,4.849,4.849,0,0,1,1.722,3.936A6.84,6.84,0,0,1,17.9-18.122a11.252,11.252,0,0,1-3.71,3.239A9.556,9.556,0,0,1,17.2-12.4a5.719,5.719,0,0,1,1.087,3.587A8.912,8.912,0,0,1,16.995-4,8.5,8.5,0,0,1,13.448-.82,11.385,11.385,0,0,1,8.364.287Zm3.772-16.2a7,7,0,0,0,3.608-6.15,3.463,3.463,0,0,0-.922-2.562,3.3,3.3,0,0,0-2.439-.922,4.793,4.793,0,0,0-3.526,1.435,5,5,0,0,0-1.435,3.69,3.017,3.017,0,0,0,1.148,2.48A17.666,17.666,0,0,0,12.136-15.908ZM8.692-1.722a5.559,5.559,0,0,0,2.972-.82A5.891,5.891,0,0,0,13.776-4.8a6.678,6.678,0,0,0,.779-3.239A3.663,3.663,0,0,0,13.9-10.25a5.159,5.159,0,0,0-1.62-1.456q-.964-.554-2.6-1.291l-.861-.369a9.524,9.524,0,0,0-3.3,3.157A8,8,0,0,0,4.346-5.9,4.113,4.113,0,0,0,5.474-2.829,4.4,4.4,0,0,0,8.692-1.722Z"
              transform="translate(918 381)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_110"
            data-name="Group 110"
            onClick={() => onChildClick(9)}
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            <g
              id="Group_77"
              data-name="Group 77"
              transform="translate(-30 76.264)"
            >
              <g
                id="Rectangle_183"
                data-name="Rectangle 183"
                transform="translate(916 125.736)"
                fill="none"
                stroke="#fff"
                stroke-width="2"
              >
                <rect width="82.627" height="82.628" stroke="none" />
                <rect x="1" y="1" width="80.627" height="80.628" fill="none" />
              </g>
            </g>
            <text
              id="_2_kohta-2"
              data-name="2 kohta"
              transform="translate(905 275)"
              fill="#fff"
              font-size="14"
              font-family="Caladea-Italic, Caladea"
              font-style="italic"
            >
              <tspan x="0" y="0">
                2 kohta
              </tspan>
            </text>
            <path
              id="Path_251"
              data-name="Path 251"
              d="M2.132-1.353A23.336,23.336,0,0,0,9.881-6.294a17.94,17.94,0,0,0,4.551-7.4,10.321,10.321,0,0,1-5.453,1.517A7.033,7.033,0,0,1,4.1-13.8a5.527,5.527,0,0,1-1.8-4.326,9.076,9.076,0,0,1,1.292-4.8,9.267,9.267,0,0,1,3.526-3.362,10.074,10.074,0,0,1,4.94-1.23,7.106,7.106,0,0,1,5.412,1.947,7.768,7.768,0,0,1,1.845,5.556,19.573,19.573,0,0,1-2.234,9.41A20.615,20.615,0,0,1,11.09-3.67,25.8,25.8,0,0,1,2.665.41ZM9.717-14.186a7.716,7.716,0,0,0,5.453-2.419,23.422,23.422,0,0,0,.328-3.854,6.527,6.527,0,0,0-.923-3.629A3.163,3.163,0,0,0,11.726-25.5a4.777,4.777,0,0,0-2.932.964,6.293,6.293,0,0,0-2.009,2.644,9.519,9.519,0,0,0-.718,3.772,4.145,4.145,0,0,0,.943,2.911A3.493,3.493,0,0,0,9.717-14.186Z"
              transform="translate(918 252)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}