import { DeleteIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Circle,
  Divider,
  Flex,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import dayjs from "dayjs";
import React, { useState } from "react";
import Cookies from "universal-cookie";
import { API_URL } from "../../../constants";
import { useAppDispatch } from "../../../store/context/app-context";
import { useBookingDispatch } from "../../../store/context/booking-context";
import dayjsPluginUTC from 'dayjs-plugin-utc'
dayjs.extend(dayjsPluginUTC)

const Booking = ({ booking, onToggleBookingEdit, onBookingDelete }) => {
  const { _id, date, type, name, attendees, bookingType, tables, info } =
    booking;

  const appDispatch = useAppDispatch();
  const bookingDispatch = useBookingDispatch();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const toast = useToast();

  const handleToggleBookingEdit = () => {
    if (onToggleBookingEdit) onToggleBookingEdit();
    appDispatch({
      type: "TOGGLE_EDIT_BOOKING",
      payload: booking,
    });
  };

  const handleToggleDeleteAlert = () => {
    setIsAlertOpen((p) => !p);
  };

  const handleDeleteBooking = () => {
    setIsDeleting(true);

    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/booking`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        _id,
      },
    })
      .then((res) => {
        setIsDeleting(false);
        const { success } = res.data;
        if (!success) {
          toast({
            position: "top-right",
            title: "Kustutamine nurjus",
            description: "Proovi hiljem uuesti.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }

        // Send callback back to parent
        if (onBookingDelete) onBookingDelete(_id);
        // Close the alert dialog
        handleToggleDeleteAlert();
        // Remove from store
        bookingDispatch({
          type: "DELETE_BOOKING",
          payload: _id,
        });
        // Show a success toast
        toast({
          position: "top-right",
          title: "Edukalt kustutatud.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(() => {
        setIsDeleting(false);
        toast({
          position: "top-right",
          title: "Kustutamine nurjus",
          description: "Proovi hiljem uuesti.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Box
        mr={4}
        mb={4}
        p={4}
        maxW="350px"
        minW={["100%", null, "350px"]}
        border="1px"
        borderColor="gray.200"
        borderRadius={4}
        bgColor="#FFF"
        position="relative"
      >
        {dayjs(date) < dayjs() && (
          <Circle
            size="10px"
            bg="red.300"
            position="absolute"
            top="-5px"
            left="-5px"
          />
        )}
        <Flex justifyContent="space-between" alignItems="center">
          <Flex h="max-content" alignItems="center">
            <Text fontSize="1.5rem" fontWeight="bold">
              {dayjs(date).utc().local().format("HH:mm")}
            </Text>
            <Badge
              ml={2}
              fontSize="0.8em"
              colorScheme={types?.find((t) => t.type === type).colorScheme}
              h="max-content"
            >
              {types?.find((t) => t.type === type).name}
            </Badge>
          </Flex>
          <Flex>
            <Button size="xs" mr={2} onClick={handleToggleDeleteAlert}>
              <DeleteIcon />
            </Button>
            <Button size="xs" onClick={handleToggleBookingEdit}>
              <SettingsIcon />
            </Button>
          </Flex>
        </Flex>
        <Divider my={2} />
        <Stack>
          <Flex justifyContent="space-between" alignItems="center">
            <Text color="gray.600">{name}</Text>
            {type === 0 && (
              <Text color="gray.600">
                {attendees} {attendees === 1 ? "inimene" : "inimest"}
              </Text>
            )}
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text color="gray.600">
              {bookingTypes &&
                bookingTypes.find((b) => b.type === bookingType)?.name}
            </Text>
            {tables?.length > 1 && (
              <Text color="gray.600">
                (Lauad -{" "}
                {tables.map((table, i) => {
                  if (tables.length === i + 1) {
                    return table.id;
                  } else {
                    return table.id + ", ";
                  }
                })}
                )
              </Text>
            )}
            {tables?.length === 1 && (
              <Text>
                (Laud -{" "}
                {tables?.map((table, i) => {
                  return table.id;
                })}
                )
              </Text>
            )}
          </Flex>
          {info && <Text color="gray.600">{info}</Text>}
        </Stack>
      </Box>

      <AlertDialog isOpen={isAlertOpen} onClose={handleToggleDeleteAlert}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Kustuta broneering
            </AlertDialogHeader>

            <AlertDialogBody>
              Oled sa kindel? Toiming on lõplik.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={handleToggleDeleteAlert} isDisabled={isDeleting}>
                Katkesta
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDeleteBooking}
                ml={3}
                isLoading={isDeleting}
              >
                Kustuta
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const types = [
  {
    type: 0,
    name: "Broneering",
    colorScheme: "green",
  },
  {
    type: 4,
    name: "Tellimus",
    colorScheme: "yellow",
  },
];

const bookingTypes = [
  {
    type: 0,
    name: "3-käiguline",
  },
  {
    type: 1,
    name: "5-käiguline",
  },
  {
    type: 2,
    name: "7-käiguline",
  },
  {
    type: 3,
    name: "Menüü kohapeal",
  },
  {
    type: 4,
    name: "Maitsev Tartu",
  },
  {
    type: 5,
    name: "Pole määratud",
  },
  {
    type: 6,
    name: "Lihuniku Äri Õhtusöök",
  },
];

export default Booking;
