import React from "react";
const AvailabilityStateContext = React.createContext();
const AvailabilityDispatchContext = React.createContext();

function availabilityReducer(state, action) {
  switch (action.type) {
    case "SET_AVAILABILITY": {
      return { ...state, data: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AvailabilityProvider({ children }) {
  const [state, dispatch] = React.useReducer(availabilityReducer, {
    data: null,
  });
  return (
    <AvailabilityStateContext.Provider value={state}>
      <AvailabilityDispatchContext.Provider value={dispatch}>
        {children}
      </AvailabilityDispatchContext.Provider>
    </AvailabilityStateContext.Provider>
  );
}

function useAvailabilityState() {
  const context = React.useContext(AvailabilityStateContext);
  if (context === undefined) {
    throw new Error(
      "useAvailabilityState must be used within a AvailabilityProvider"
    );
  }
  return context;
}

function useAvailabilityDispatch() {
  const context = React.useContext(AvailabilityDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useAvailabilityDispatch must be used within a AvailabilityProvider"
    );
  }
  return context;
}

export { AvailabilityProvider, useAvailabilityState, useAvailabilityDispatch };
