import { useToast } from "@chakra-ui/react";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { API_URL } from "../constants";
import {
  useOrderDispatch,
  useOrderState,
} from "../store/context/order-context";

export default function useGetOrders() {
  const { data, isFetchedOrder } = useOrderState();
  const dispatch = useOrderDispatch();
  const toast = useToast();

  useEffect(() => {
    if (!isFetchedOrder) {
      const cookies = new Cookies(),
        token = cookies.get("token");
      if (!token) return;
      axios({
        url: `${API_URL}/orders`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const { success, data } = res.data;
          if (!success) return;

          // Order the Orders by date
          const sortedOrders = data.sort(
            (a, b) => dayjs(a.date) - dayjs(b.date)
          );
          dispatch({
            type: "SET_ORDERS",
            payload: sortedOrders,
          });
        })
        .catch(() => {
          dispatch({
            type: "SET_ORDERS_ERROR",
          });
          toast({
            position: "top-right",
            title: "Tellimuste pärimine nurjus.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }
  }, []);
}
