import { AddIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import React from "react";
import { useAppDispatch } from "../../../store/context/app-context";

const AddNewBooking = () => {
  const dispatch = useAppDispatch();

  const handleAddNewBooking = () => {
    dispatch({
      type: "TOGGLE_ADD_BOOKING",
    });
  };
  return (
    <Button
      leftIcon={<AddIcon />}
      variant="solid"
      onClick={handleAddNewBooking}
    >
      Lisa uus
    </Button>
  );
};

export default AddNewBooking;
