import dayjs from "dayjs";
import React from "react";
const BookingStateContext = React.createContext();
const BookingDispatchContext = React.createContext();

function bookingReducer(state, action) {
  switch (action.type) {
    case "SET_BOOKINGS": {
      return { ...state, data: action.payload, isFetched: true };
    }
    case "ADD_BOOKING": {
      if (state.data) {
        // Enter the booking in the correct place in the array
        const newBookings = [...state.data, action.payload];
        const sortedBookings = newBookings.sort(
          (a, b) => dayjs(a.date) - dayjs(b.date)
        );
        return {
          ...state,
          data: sortedBookings,
        };
      }
      return {
        ...state,
        data: [action.payload],
      };
    }
    case "EDIT_BOOKING": {
      const newBookingIndex = state.data.findIndex(
        (booking) => booking._id === action.payload._id
      );
      const newBookings = state.data.map((booking, index) => {
        if (index === newBookingIndex) return action.payload;
        return booking;
      });
      // Enter the booking in the correct place in the array
      const sortedBookings = newBookings.sort(
        (a, b) => dayjs(a.date) - dayjs(b.date)
      );
      return {
        ...state,
        data: sortedBookings,
      };
    }
    case "SET_BOOKINGS_ERROR": {
      return { ...state, isError: true, isFetched: true };
    }
    case "DELETE_BOOKING": {
      if (!state?.data) return state;
      return {
        ...state,
        data: state.data.filter(({ _id }) => _id !== action.payload),
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function BookingProvider({ children }) {
  const [state, dispatch] = React.useReducer(bookingReducer, {
    data: null,
    isFetched: false,
    isError: false,
  });
  return (
    <BookingStateContext.Provider value={state}>
      <BookingDispatchContext.Provider value={dispatch}>
        {children}
      </BookingDispatchContext.Provider>
    </BookingStateContext.Provider>
  );
}

function useBookingState() {
  const context = React.useContext(BookingStateContext);
  if (context === undefined) {
    throw new Error("useBookingState must be used within a BookingProvider");
  }
  return context;
}

function useBookingDispatch() {
  const context = React.useContext(BookingDispatchContext);
  if (context === undefined) {
    throw new Error("useBookingDispatch must be used within a BookingProvider");
  }
  return context;
}

export { BookingProvider, useBookingState, useBookingDispatch };
