import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Spacer,
  StackDivider,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Cookies from "universal-cookie";
import { API_URL } from "../../constants";
import "../../only-timepicker.css";
import {
  useUserDispatch,
  useUserState,
} from "../../store/context/user-context";

const Availability = () => {
  const { restaurant } = useUserState();
  const [fields, setFields] = useState([]);
  const [amountOfOpenings, setAmountOfOpenings] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const dispatch = useUserDispatch();

  // Set the restaurant fields
  useEffect(() => {
    if (restaurant) {
      setFields(restaurant?.availability);
      // Set the corrent length of amountOfOpenings
      setAmountOfOpenings(restaurant?.availability?.length);
    }
  }, [restaurant]);

  const handleDayChange = (value, objKey, idx) => {
    const copy = fields;
    const elementToEdit = copy?.find((_, i) => i === idx);
    if (elementToEdit) {
      const newFields = copy.map((field, i) => {
        if (i === idx) {
          return {
            ...field,
            [objKey]: {
              ...field?.[objKey],
              day: +value,
            },
          };
        }
        return field;
      });
      setFields(newFields);
    } else {
      setFields((p) => [
        ...p,
        {
          [objKey]: {
            day: +value,
          },
        },
      ]);
    }
  };

  const handleTimeChange = (objKey, val, idx) => {
    const copy = fields;
    const newFields = copy.map((field, i) => {
      if (i === idx) {
        return {
          ...field,
          [objKey]: {
            ...field?.[objKey],
            time: val,
          },
        };
      }
      return field;
    });
    setFields(newFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/availability`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        restaurantId: restaurant?._id,
        availability: fields,
      },
    })
      .then((res) => {
        setIsSubmitting(false);
        const { success } = res.data;
        if (!success) {
          failureToast();
          return;
        }
        successToast();
        dispatch({
          type: "UPDATE_AVAILABILITY",
          payload: fields,
        });
      })
      .catch(() => {
        setIsSubmitting(false);
        failureToast();
      });
  };

  const failureToast = useCallback(
    () =>
      toast({
        position: "top-right",
        title: "Salvestamine nurjus.",
        description: "Proovi hiljem uuesti.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      }),
    [toast]
  );

  const successToast = useCallback(
    () =>
      toast({
        position: "top-right",
        title: "Edukalt salvestatud.",
        status: "success",
        duration: 3000,
        isClosable: true,
      }),
    [toast]
  );

  return (
    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
      <VStack>
        {[...Array(amountOfOpenings)].map((_, i) => {
          const isOpenTimePickerAvailable = !fields[i]?.open?.day;
          const isCloseTimePickerAvailable = !fields[i]?.close?.day;
          const openTimepickerValue = fields[i]?.open?.time;
          const closeTimePickerValue = fields[i]?.close?.time;
          return (
            <Fragment key={i}>
              <HStack w="100%">
                <FormControl id={`day-${i}`} isRequired>
                  <FormLabel>Avamise päev</FormLabel>
                  <Select
                    placeholder="Vali avamise päev"
                    value={fields[i]?.open?.day}
                    onChange={({ target }) =>
                      handleDayChange(target.value, "open", i)
                    }
                  >
                    <option value={1}>Esmaspäev</option>
                    <option value={2}>Teisipäev</option>
                    <option value={3}>Kolmapäev</option>
                    <option value={4}>Neljapäev</option>
                    <option value={5}>Reede</option>
                    <option value={6}>Laupäev</option>
                    <option value={7}>Pühapäev</option>
                  </Select>
                </FormControl>
                <FormControl isRequired isDisabled={isOpenTimePickerAvailable}>
                  <FormLabel htmlFor={`openTime-${i}`}>Kellaaeg</FormLabel>
                  <div className="only-timepicker">
                    <DatePicker
                      id={`openTime-${i}`}
                      disabled={isOpenTimePickerAvailable}
                      placeholderText="00:00"
                      selected={
                        openTimepickerValue
                          ? new Date(openTimepickerValue)
                          : null
                      }
                      onChange={(date) => handleTimeChange("open", date, i)}
                      showTimeSelect={true}
                      showTimeSelectOnly={true}
                      dateFormat="HH:mm"
                      minDate={new Date()}
                      timeCaption="Kellaaeg"
                      autoComplete="off"
                    />
                  </div>
                </FormControl>
              </HStack>
              <StackDivider />
              <HStack w="100%">
                <FormControl id={`day--${i}`} isRequired>
                  <FormLabel>Sulgemise päev</FormLabel>
                  <Select
                    placeholder="Vali sulgemise päev"
                    value={fields[i]?.close?.day}
                    onChange={({ target }) =>
                      handleDayChange(target.value, "close", i)
                    }
                  >
                    <option value={1}>Esmaspäev</option>
                    <option value={2}>Teisipäev</option>
                    <option value={3}>Kolmapäev</option>
                    <option value={4}>Neljapäev</option>
                    <option value={5}>Reede</option>
                    <option value={6}>Laupäev</option>
                    <option value={7}>Pühapäev</option>
                  </Select>
                </FormControl>
                <FormControl isRequired isDisabled={isCloseTimePickerAvailable}>
                  <FormLabel htmlFor={`closeTime-${i}`}>Kellaaeg</FormLabel>
                  <div className="only-timepicker">
                    <DatePicker
                      id={`closeTime-${i}`}
                      disabled={isCloseTimePickerAvailable}
                      placeholderText="00:00"
                      selected={
                        closeTimePickerValue
                          ? new Date(closeTimePickerValue)
                          : null
                      }
                      onChange={(date) => handleTimeChange("close", date, i)}
                      showTimeSelect={true}
                      showTimeSelectOnly={true}
                      dateFormat="HH:mm"
                      minDate={new Date()}
                      timeCaption="Kellaaeg"
                      autoComplete="off"
                    />
                  </div>
                </FormControl>
              </HStack>
              {i !== amountOfOpenings - 1 ? (
                <Box h="35px" w="100%" display="flex" alignItems="center">
                  <Divider />
                </Box>
              ) : (
                <>
                  <HStack w="100%" pt={4}>
                    <Spacer />
                    <Button
                      isDisabled={amountOfOpenings === 7}
                      onClick={() =>
                        setAmountOfOpenings((p) => (p < 7 ? p + 1 : p))
                      }
                    >
                      Lisa uus päev
                    </Button>
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isLoading={isSubmitting}
                    >
                      Salvesta
                    </Button>
                  </HStack>
                  <Box h={["50px", "75px", "100px", "200px"]} />
                </>
              )}
            </Fragment>
          );
        })}
      </VStack>
    </form>
  );
};

export default Availability;
