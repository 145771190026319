import { DeleteIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Checkbox,
  Circle,
  Divider,
  Flex,
  FormControl,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { API_URL } from "../../../constants";
import { useAppDispatch } from "../../../store/context/app-context";
import { useOrderDispatch } from "../../../store/context/order-context";
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(timezone);

const Order = ({ order, onToggleOrderEdit, onOrderDelete }) => {
  const { _id, date, type, name, product, completed /* quantity */ } = order;
  const appDispatch = useAppDispatch();
  const orderDispatch = useOrderDispatch();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [doneOrders, setDoneOrders] = useState(completed);
  const toast = useToast();

  const handleToggleOrderEdit = () => {
    if (onToggleOrderEdit) onToggleOrderEdit();
    appDispatch({
      type: "TOGGLE_EDIT_ORDER",
      payload: order,
    });
  };

  const handleToggleDeleteAlert = () => {
    setIsAlertOpen((p) => !p);
  };

  const handleDeleteOrder = () => {
    setIsDeleting(true);

    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/order`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        _id,
      },
    })
      .then((res) => {
        setIsDeleting(false);
        const { success } = res.data;
        if (!success) {
          toast({
            position: "top-right",
            title: "Kustutamine nurjus",
            description: "Proovi hiljem uuesti.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }

        // Send callback back to parent
        if (onOrderDelete) onOrderDelete(_id);
        // Close the alert dialog
        handleToggleDeleteAlert();
        // Remove from store
        orderDispatch({
          type: "DELETE_ORDER",
          payload: _id,
        });
        // Show a success toast
        toast({
          position: "top-right",
          title: "Edukalt kustutatud.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(() => {
        setIsDeleting(false);
        toast({
          position: "top-right",
          title: "Kustutamine nurjus",
          description: "Proovi hiljem uuesti.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleCheckedOrder = (e, done) => {
    setDoneOrders(!doneOrders);
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/orderStatus`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        _id: _id,
        completed: !doneOrders,
      },
    }).then((res) => {});
  };

  return (
    <>
      <Box
        mr={4}
        mb={4}
        p={4}
        maxW="350px"
        minW={["100%", null, "350px"]}
        border="1px"
        borderColor={!doneOrders ? "gray.200" : "#75CE8D"}
        borderRadius={4}
        bgColor={!doneOrders ? "#FFF" : "#75CE8D"}
        position="relative"
      >
        {dayjs(date) < dayjs() && (
          <Circle
            size="10px"
            bg="red.300"
            position="absolute"
            top="-5px"
            left="-5px"
          />
        )}
        <Flex justifyContent="space-between" alignItems="center">
          <Flex h="max-content" alignItems="center">
            <Text fontSize="1.5rem" fontWeight="bold">
              {dayjs(date).tz("Europe/Tallinn").format("HH:mm")}
            </Text>
            <Badge
              ml={2}
              fontSize="0.8em"
              colorScheme={types?.find((t) => t.type === type).colorScheme}
              h="max-content"
            >
              {types?.find((t) => t.type === type).name}
            </Badge>
          </Flex>
          <Flex>
            <Button size="xs" mr={2} onClick={handleToggleDeleteAlert}>
              <DeleteIcon />
            </Button>
            <Button size="xs" onClick={handleToggleOrderEdit}>
              <SettingsIcon />
            </Button>
            <Box ml={2}>
              <FormControl>
                <Checkbox
                  value={_id}
                  size="lg"
                  onChange={(e) => {
                    handleCheckedOrder(
                      e.target.value,
                      doneOrders ? true : false
                    );
                  }}
                  isChecked={doneOrders ? true : false}
                ></Checkbox>
              </FormControl>
            </Box>
          </Flex>
        </Flex>
        <Divider my={2} />
        <Stack>
          <Flex justifyContent="space-between" alignItems="center">
            <Text color={!doneOrders ? "gray.600" : "#fff"}>{name}</Text>
            {type === 4 && (
              <Text color={!doneOrders ? "gray.600" : "#fff"}></Text>
            )}
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text color={!doneOrders ? "gray.600" : "#fff"}>{product}</Text>
            {/*           <Text color="gray.600">Kogus: {quantity}</Text> */}
          </Flex>
        </Stack>
      </Box>

      <AlertDialog isOpen={isAlertOpen} onClose={handleToggleDeleteAlert}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Kustuta tellimus
            </AlertDialogHeader>

            <AlertDialogBody>
              Oled sa kindel? Toiming on lõplik.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={handleToggleDeleteAlert} isDisabled={isDeleting}>
                Katkesta
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDeleteOrder}
                ml={3}
                isLoading={isDeleting}
              >
                Kustuta
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const types = [
  {
    type: 0,
    name: "Broneering",
    colorScheme: "green",
  },
  {
    type: 4,
    name: "Tellimus",
    colorScheme: "yellow",
  },
];

export default Order;
