import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Header from "../Header";
import { EmailIcon, ViewIcon } from "@chakra-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import dayjs from "dayjs";
import { API_URL } from "../../constants";
import { useUserDispatch } from "../../store/context/user-context";

const Login = () => {
  const [show, setShow] = useState(false);
  const [inputs, setInputs] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const dispatch = useUserDispatch();

  const handleInputChange = (target) => {
    setInputs((p) => ({ ...p, [target.id]: target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors(null);
    axios({
      url: `${API_URL}/login`,
      method: "POST",
      data: inputs,
    })
      .then((res) => {
        setIsSubmitting(false);
        const { success, errors, user, token } = res.data;
        if (!success) {
          if (errors) {
            setErrors(errors);
            toast({
              position: "top-right",
              title: "Sisselogimine nurjus.",
              description: "Kontrolli ning proovi uuesti.",
              status: "warning",
              duration: 5000,
              isClosable: true,
            });
          }
          return;
        }

        // We have the token and user object in this state

        // Add the token
        const cookies = new Cookies();
        cookies.set("token", token, {
          path: "/",
          expires: dayjs().add(14, "day").toDate(),
        });

        // Set the user to context
        dispatch({
          type: "SET_USER",
          payload: user,
        });
      })
      .catch((err) => {
        setIsSubmitting(false);
        toast({
          position: "top-right",
          title: "Midagi läks valesti.",
          description: "Sisselogimine nurjus, proovi hiljem uuesti.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Box maxH="100vh">
      <Header />
      <Box h="calc(100vh - 100px)" bgColor="#FEFEFE">
        <Container
          centerContent
          minH="calc(100vh - 300px)"
          display="flex"
          justifyContent="center"
        >
          <Heading>Logi sisse</Heading>
          <Flex p={[4, 6, 8, 10]} m={4} bgColor="white" borderRadius={4}>
            <form onSubmit={handleSubmit} noValidate>
              <Stack spacing={4}>
                <FormControl id="email" isRequired isInvalid={errors?.email}>
                  <FormLabel>E-maili aadress</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<EmailIcon color="gray.400" />}
                    />
                    <Input
                      type="email"
                      placeholder="E-maili aadress"
                      isRequired
                      onChange={({ target }) => handleInputChange(target)}
                      isInvalid={errors?.email}
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors?.email?.msg}</FormErrorMessage>
                </FormControl>
                <FormControl
                  id="password"
                  isRequired
                  isInvalid={errors?.password}
                >
                  <FormLabel>Parool</FormLabel>
                  <InputGroup>
                    <Input
                      pr="4.5rem"
                      type={show ? "text" : "password"}
                      placeholder="Parool"
                      onChange={({ target }) => handleInputChange(target)}
                      isRequired
                      isInvalid={errors?.password}
                    />
                    <InputRightElement
                      children={
                        <Button
                          onClick={() => setShow((p) => !p)}
                          variant="ghost"
                          w="2.25rem"
                          minW="2.25rem"
                          h="2rem"
                          mr="0.5rem"
                        >
                          <ViewIcon color={!show ? "gray.400" : "gray.700"} />
                        </Button>
                      }
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors?.password?.msg}</FormErrorMessage>
                </FormControl>
              </Stack>
              <Flex mt={6} w="100%" justifyContent="flex-end">
                <Button type="submit" isLoading={isSubmitting}>
                  Logi sisse
                </Button>
              </Flex>
            </form>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default Login;
