import { Box, Container, Divider, Flex, Heading } from "@chakra-ui/react";
import React, { useState } from "react";
import Header from "../Header";
import Table from "./Table";
import TableMap from "./TableMap";
import TableModal from "./TableModal";

const TableLayout = () => {
  const [selectedTable, setSelectedTable] = useState(null);
  const onChildClick = (id) => {
    setSelectedTable(id);
  };

  const closeModal = () => setSelectedTable(null);
  return (
    <>
      <Header />
      <Container maxW="1720px" py={8}>
        <Heading>Lauaplaan</Heading>
        <Divider />
        <Box h={10} />

        <TableMap onChildClick={onChildClick} />
      </Container>
      <TableModal
        isOpen={selectedTable}
        table={tables.find((t) => t.id === selectedTable)}
        onClose={closeModal}
        onToggleBookingEdit={closeModal}
      />
    </>
  );
};

const tables = [
  {
    id: 1,
    name: "Laud 1",
  },
  {
    id: 2,
    name: "Laud 2",
  },
  {
    id: 3,
    name: "Laud 3",
  },
  {
    id: 4,
    name: "Laud 4",
  },
  {
    id: 5,
    name: "Laud 5",
  },
  {
    id: 6,
    name: "Laud 6",
  },
  {
    id: 7,
    name: "Laud 7",
  },
  {
    id: 8,
    name: "Laud 8",
  },
  {
    id: 9,
    name: "Laud 9",
  },
  {
    id: 10,
    name: "Laud 10",
  },
];

export default TableLayout;
