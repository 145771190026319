import { useToast } from "@chakra-ui/react";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { API_URL } from "../constants";
import {
  useBookingDispatch,
  useBookingState,
} from "../store/context/booking-context";

export default function useGetBookings() {
  const { data, isFetched } = useBookingState();
  const dispatch = useBookingDispatch();
  const toast = useToast();

  // If we haven't fetched bookings yet, fetch them
  useEffect(() => {
    if (!isFetched) {
      const cookies = new Cookies(),
        token = cookies.get("token");
      if (!token) return;
      axios({
        url: `${API_URL}/bookings`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const { success, data } = res.data;
          if (!success) return;

          // Order the bookings by date
          const sortedBookings = data.sort(
            (a, b) => dayjs(a.date) - dayjs(b.date)
          );
          dispatch({
            type: "SET_BOOKINGS",
            payload: sortedBookings,
          });
        })
        .catch(() => {
          dispatch({
            type: "SET_BOOKINGS_ERROR",
          });
          toast({
            position: "top-right",
            title: "Broneeringute pärimine nurjus.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }
  }, [data, isFetched, dispatch, toast]);
}
