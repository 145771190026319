import dayjs from "dayjs";

const generateAvailableOrderDays = (orders) => {
  // We will generate an array of sorted available days from orders
  const result = [];
  for (const order of orders) {
    const orderDay = dayjs(order.date).format("YYYY-MM-DD");
    if (!result.includes(orderDay)) result.push(orderDay);
  }
  return result;
};

export default generateAvailableOrderDays;
