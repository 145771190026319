import dayjs from "dayjs";

const generateAvailableorders = (orders, from, to) => {
  if (!orders || orders?.length <= 0) return null;

  const result = [];
  for (const order of orders) {
    // We skip orders that are not active
    if (!order.active) continue;
    // We exclude all orders that don't fit from [from] to [to]
    const orderDate = dayjs(order.date);
    if (orderDate < dayjs(from) || orderDate > dayjs(to)) continue;
    // Here we have the filtered orders
    result.push(order);
  }
  return result;
};

export default generateAvailableorders;
