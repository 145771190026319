import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Radio,
  Stack,
  Textarea,
  useToast,
  Checkbox,
  VStack,
  NumberInputStepper,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInput,
} from "@chakra-ui/react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { API_URL } from "../../constants";
import { useAppDispatch, useAppState } from "../../store/context/app-context";
import { useAvailabilityState } from "../../store/context/availability-context";
import {
  useBookingDispatch,
  useBookingState,
} from "../../store/context/booking-context";
import {
  useOrderDispatch,
  useOrderState,
} from "../../store/context/order-context";
import AttendeeControl from "./AttendeeControl";
import DateSelector from "./DateSelector";
import useAttendeeCount from "./useAttendeeCount";
import TABLES from "./tables";
//import { getProducts } from "../../utils/woocommerce";

const BookingCreator = () => {
  const {
    isCreatorModalOpen,
    isEditingBooking,
    isEditingOrder,
    editBookingData,
    editOrderData,
  } = useAppState();
  const appDispatch = useAppDispatch();
  const bookingDispatch = useBookingDispatch();
  const orderDispatch = useOrderDispatch();
  const toast = useToast();
  const [inputs, setInputs] = useState({ type: 1 });
  const [errors, setErrors] = useState(null);
  const [availableTables, setAvailableTables] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: availability } = useAvailabilityState();
  const { data: booking } = useBookingState();
  const { data: order } = useOrderState();
  const { excludedPeriods, alwaysExcludedDates, timeframes } =
    availability || {};
  const [
    currentDateAttendeeCount,
    setCurrentDateAttendeeCount,
    calculateCurrentDateAttendeeCount,
    maximumAttendeeCount,
  ] = useAttendeeCount();

  /*   const [wooProducts, setWooProducts] = useState([]);
  const [page, setPage] = useState(1); */

  /*  useEffect(() => {
    getProducts(page).then(function (res) {
      const data = JSON.parse(res.toJSON().body);
      if (data.length === 100) {
        setPage((p) => p + 1);
      }
      data.map((dat) => {
        return tooted.push(dat);
      });
      setWooProducts([...wooProducts, data]);
    });
  }, [page]);
 */

  availableTables?.sort((a, b) => a - b);

  const handleInputChange = ({ target }) => {
    setInputs((p) => ({ ...p, [target.id]: target.value }));
  };

  const handleSingleValueChange = (value, name) => {
    setInputs((p) => ({ ...p, [name]: value }));
  };

  const handleTableSelect = (id, capacity) => {
    const exists = inputs?.tables?.find((table) => table.id === +id);
    if (!exists) {
      setInputs((p) => ({
        ...p,
        tables: p?.tables
          ? [...p.tables, { id: +id, capacity: capacity }]
          : [{ id: +id, capacity: capacity }],
      }));
    } else {
      const newTables = inputs?.tables.filter((table) => table.id !== +id);
      setInputs((p) => ({
        ...p,
        tables: newTables,
      }));
    }
  };

  const handleDrawerClose = () => {
    if (inputs?.type === 1) {
      appDispatch({
        type: isEditingBooking ? "TOGGLE_EDIT_BOOKING" : "TOGGLE_ADD_BOOKING",
      });
    } else {
      appDispatch({
        type: isEditingOrder ? "TOGGLE_EDIT_ORDER" : "TOGGLE_ADD_ORDER",
      });
    }
  };

  // Handle setting [editBookingData] to inputs
  useEffect(() => {
    if (editBookingData) {
      setInputs({
        ...editBookingData,
        type: editBookingData.type + 1, // Related to [Radio] error
      });
      const cookies = new Cookies(),
        token = cookies.get("token");

      axios({
        url: `${API_URL}/table-bookings`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date: editBookingData.date,
          editing: isEditingBooking,
          tables: editBookingData.tables,
        },
      }).then((res) => {
        setAvailableTables(res.data.data);

        /*     setInputs((p) => ({
          ...p,
          tables: p?.tables ? [...p.tables, { [key]: val }] : [{ [key]: val }],
        })); */
        setInputs((p) => ({ ...p, tables: editBookingData.tables }));
        calculateCurrentDateAttendeeCount(editBookingData.date, timeframes);
      });
    }
    if (editOrderData) {
      setInputs({
        ...editOrderData,
        type: editOrderData.type + 1,
      });
    }
  }, [editBookingData, editOrderData]);

  const getTablesForBooking = (notSelectedTables, peopleLeft) => {
    let tableSelected = false;
    let table = 0;
    let index = 0;
    for (const [i, availableTable] of notSelectedTables.entries()) {
      if (peopleLeft <= availableTable.capacity) {
        index = i;
        table = availableTable;
        tableSelected = true;
        break;
      }
    }
    if (!tableSelected) {
      const firstTable = notSelectedTables[notSelectedTables.length - 1];
      peopleLeft = peopleLeft - firstTable?.capacity;
      return {
        table: firstTable,
        peopleLeft: peopleLeft,
        index: notSelectedTables.length - 1,
      };
    }
    return { table: table, peopleLeft: 0, index: index };
  };

  // Clear all of the values when modal is closed
  useEffect(() => {
    if (!isCreatorModalOpen) {
      setInputs({ type: 1 });
      setErrors(null);
      //setAvailability(null);
      setCurrentDateAttendeeCount(0);
    }
  }, [isCreatorModalOpen, setCurrentDateAttendeeCount]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors(null);
    const cookies = new Cookies(),
      token = cookies.get("token");

    const correctedType = inputs?.type - 1;

    if (correctedType === 0) {
      const capacityArray = [];
      const notSelectedTables = [];
      const autoTableSelection = [];
      let selectedTables = [];

      if (inputs?.tables) {
        inputs?.tables?.map((table) => {
          capacityArray.push(table.capacity);
        });
      }

      if (!inputs?.tables) {
        let autoSelectionCapacity = 0;
        availableTables?.forEach((avTable) => {
          TABLES.filter((table) => {
            if (table.id === avTable) {
              return notSelectedTables.push({
                id: table.id,
                capacity: table.capacity,
              });
            }
          });
        });

        if (inputs?.attendees) {
          notSelectedTables.sort((a, b) => {
            return a.capacity - b.capacity;
          });

          let peopleInNeedForTable = inputs?.attendees;

          while (peopleInNeedForTable > 0) {
            const tableSelect = getTablesForBooking(
              notSelectedTables,
              peopleInNeedForTable
            );
            peopleInNeedForTable = tableSelect.peopleLeft;
            selectedTables.push(tableSelect.table);
            notSelectedTables.splice(tableSelect.index, 1);
            if (peopleInNeedForTable <= 0) {
              break;
            }
          }

          if (!inputs?.tables) {
            selectedTables.map((table) => {
              capacityArray.push(table.capacity);
            });
          }
        }
      }

      const totalCapacity = capacityArray.reduce((a, b) => a + b, 0);

      const submissionData = {
        ...inputs,
        type: correctedType,
        bookingType: !inputs?.bookingType ? 0 : inputs?.bookingType,
        attendees: correctedType === 1 ? null : inputs?.attendees,
        tables: !inputs?.tables ? selectedTables : inputs?.tables,
      };

      if (+inputs?.attendees <= totalCapacity) {
        axios({
          url: `${API_URL}/booking`,
          method: isEditingBooking ? "PUT" : "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: submissionData,
        })
          .then((res) => {
            setIsSubmitting(false);
            const { success, errors, data } = res.data;
            if (!success) {
              if (errors) setErrors(errors);
              return;
            }
            // Add the booking to the store
            if (isEditingBooking) {
              bookingDispatch({
                type: "EDIT_BOOKING",
                payload: submissionData,
              });
            } else {
              bookingDispatch({
                type: "ADD_BOOKING",
                payload: {
                  ...submissionData,
                  active: true,
                  _id: data?._id,
                },
              });
            }
            // Close the drawer
            handleDrawerClose();
            // Show a toast
            toast({
              position: "top-right",
              title: isEditingBooking
                ? "Broneering edukalt muudetud."
                : "Broneering edukalt lisatud",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          })
          .catch(() => {
            setIsSubmitting(false);
            toast({
              position: "top-right",
              title: "Midagi läks valesti.",
              description: "Proovi hiljem uuesti.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          });
      } else {
        setIsSubmitting(false);
        toast({
          position: "top-right",
          title: "Inimeste arv ületab laudade kohti.",
          description: "Vali rohkem laudu.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else if (correctedType === 4) {
      const submissionData = {
        ...inputs,
        type: correctedType,
      };

      axios({
        url: `${API_URL}/order`,
        method: isEditingOrder ? "PUT" : "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: submissionData,
      })
        .then((res) => {
          setIsSubmitting(false);

          const { success, errors, data } = res.data;
          if (!success) {
            if (errors) setErrors(errors);
            return;
          }
          if (isEditingOrder) {
            orderDispatch({
              type: "EDIT_ORDER",
              payload: submissionData,
            });
          } else {
            orderDispatch({
              type: "ADD_ORDER",
              payload: {
                ...submissionData,
                active: true,
                _id: data?._id,
              },
            });
          }

          handleDrawerClose();
          // Show a toast
          toast({
            position: "top-right",
            title: isEditingBooking
              ? "Tellimus edukalt muudetud."
              : "Tellimus edukalt lisatud",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        })
        .catch(() => {
          setIsSubmitting(false);
          toast({
            position: "top-right",
            title: "Midagi läks valesti.",
            description: "Proovi hiljem uuesti.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  const bookingTypeOptions = [
    { value: 0, label: "3-käiguline" },
    { value: 1, label: "5-käiguline" },
    { value: 2, label: "7-käiguline" },
    { value: 3, label: "Menüü kohapeal" },
    { value: 4, label: "Maitsev Tartu" },
    { value: 5, label: "Määramata" },
    { value: 6, label: "Lihuniku Äri Õhtusöök" },
    { value: 7, label: "Metsast taldrikule" },
  ];

  const handleDateChange = (date, ids) => {
    const cookies = new Cookies(),
      token = cookies.get("token");

    axios({
      url: `${API_URL}/table-bookings`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        date: date,
        editing: isEditingBooking,
        tables: ids,
      },
    }).then((res) => {
      setAvailableTables(res.data.data);
    });
  };

  // Radio values are bugged [https://github.com/chakra-ui/chakra-ui/issues/50]
  const productOptions = [];

  // console.log(wooProducts);
  /*  wooProducts?.map((prod) => {
    productOptions.push({ value: prod.name, label: prod.name });
  }); */

  return (
    <Drawer
      isOpen={isCreatorModalOpen}
      placement="right"
      onClose={handleDrawerClose}
      closeOnOverlayClick={false}
      size="sm"
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {isEditingBooking ? "Muuda broneeringut" : "Lisa broneering"}
          </DrawerHeader>
          <form onSubmit={handleSubmit} noValidate>
            <DrawerBody height="calc(100vh - 61.6px - 72px)">
              <FormControl id="type" isRequired isInvalid={errors?.type}>
                <FormLabel>Tüüp</FormLabel>
                <Stack direction="row">
                  <Radio
                    value={1}
                    onChange={(e) =>
                      handleSingleValueChange(+e.target.value, "type")
                    }
                    isInvalid={!inputs?.type && errors?.type}
                    isChecked={inputs?.type === 1 || false}
                  >
                    Broneering
                  </Radio>

                  <Radio
                    value={5}
                    onChange={(e) =>
                      handleSingleValueChange(+e.target.value, "type")
                    }
                    isInvalid={!inputs?.type && errors?.type}
                    isChecked={inputs?.type === 5 || false}
                  >
                    Tellimus
                  </Radio>
                </Stack>
                <FormErrorMessage>{errors?.type?.msg}</FormErrorMessage>
              </FormControl>
              <Box h="1rem" />
              {inputs?.type === 1 || inputs?.type === undefined ? (
                <>
                  <DateSelector
                    selected={inputs?.date}
                    isInvalid={errors?.date}
                    excludedPeriods={excludedPeriods}
                    alwaysExcludedDates={alwaysExcludedDates}
                    onChangeCallback={(date) => {
                      calculateCurrentDateAttendeeCount(date, timeframes);
                      handleSingleValueChange(date, "date");
                      handleDateChange(date, inputs?.tables);
                    }}
                  />
                  <Box h="1rem" />
                  <Stack direction="row">
                    <FormControl id="name" isRequired isInvalid={errors?.name}>
                      <FormLabel>Nimi</FormLabel>
                      <InputGroup>
                        <Input
                          id="name"
                          placeholder="Nimi"
                          isRequired
                          onChange={handleInputChange}
                          isInvalid={errors?.name}
                          value={inputs?.name || ""}
                        />
                      </InputGroup>
                      <FormErrorMessage>{errors?.name?.msg}</FormErrorMessage>
                    </FormControl>
                    <AttendeeControl
                      value={inputs?.attendees}
                      isInvalid={errors?.attendees && inputs?.type === 1}
                      isDisabled={
                        !inputs?.date || (inputs?.type && inputs?.type !== 1)
                      }
                      currentDateAttendeeCount={currentDateAttendeeCount}
                      maximumAttendeeCount={maximumAttendeeCount}
                      onChangeCallback={(val) =>
                        handleSingleValueChange(+val, "attendees")
                      }
                      availableTables={availableTables}
                      tables={inputs?.tables}
                    />
                  </Stack>
                  <Box h="1rem" />
                  <Stack direction="row">
                    <FormControl id="email" isInvalid={errors?.email}>
                      <FormLabel>E-maili aadress</FormLabel>
                      <InputGroup>
                        <Input
                          id="email"
                          type="email"
                          placeholder="E-maili aadress"
                          onChange={handleInputChange}
                          isInvalid={errors?.email}
                          value={inputs?.email || ""}
                        />
                      </InputGroup>
                      <FormErrorMessage>{errors?.email?.msg}</FormErrorMessage>
                    </FormControl>
                    <FormControl id="phone" isInvalid={errors?.phone}>
                      <FormLabel>Telefon</FormLabel>
                      <InputGroup>
                        <Input
                          id="phone"
                          type="phone"
                          placeholder="Telefoni number"
                          onChange={handleInputChange}
                          isInvalid={errors?.phone}
                          value={inputs?.phone || ""}
                        />
                      </InputGroup>
                      <FormErrorMessage>{errors?.phone?.msg}</FormErrorMessage>
                    </FormControl>
                  </Stack>
                  <Box h="1rem" />
                  <FormControl id="table" isRequired isInvalid={errors?.tables}>
                    <FormLabel>Laud</FormLabel>
                    <VStack align="flex-start">
                      {TABLES?.map((laud, i) => {
                        const isAvailable = availableTables.find(
                          (el) => el === laud.id
                        )
                          ? true
                          : false;

                        return (
                          <Checkbox
                            key={laud.id}
                            value={`${laud.id}`}
                            colorScheme="green"
                            onChange={(e) => {
                              handleTableSelect(
                                `${+e.target.value}`,
                                +laud.capacity
                              );
                            }}
                            isChecked={
                              inputs?.tables?.find(
                                (table) => table.id === laud.id
                              ) || false
                            }
                          >
                            {isAvailable ? (
                              <span>
                                {" "}
                                Laud {laud.id} ({laud.capacity} kohta)
                              </span>
                            ) : (
                              <span >
                                {" "}
                                Laud {laud.id} ({laud.capacity} kohta)
                                <span style={{color: 'red'}}> - Laud broneeritud</span>
                              </span>
                            )}
                          </Checkbox>
                        );
                      })}
                    </VStack>
                    <FormErrorMessage>{errors?.tables?.msg}</FormErrorMessage>
                  </FormControl>
                  <Box h="1rem" />
                  <FormControl
                    id="bookingType"
                    isRequired
                    isInvalid={errors?.bookingType}
                  >
                    <FormLabel>Broneeringu tüüp</FormLabel>
                    <Select
                      value={bookingTypeOptions.find(
                        (book) => book.value === inputs?.bookingType
                      )}
                      onChange={(e) =>
                        handleSingleValueChange(e.value, "bookingType")
                      }
                      options={bookingTypeOptions}
                      isSearchable
                    />

                    <FormErrorMessage>
                      {errors?.bookingType?.msg}
                    </FormErrorMessage>
                  </FormControl>
                  <Box h="1rem" />
                  <FormControl id="info">
                    <FormLabel>Lisainfo</FormLabel>
                    <Textarea
                      value={inputs?.info || ""}
                      onChange={handleInputChange}
                      placeholder="Lisainfo"
                    />
                  </FormControl>
                </>
              ) : (
                <>
                  <DateSelector
                    selected={inputs?.date}
                    isInvalid={errors?.date}
                    onChangeCallback={(date) => {
                      handleSingleValueChange(date, "date");
                    }}
                  />
                  <Box my="1rem" />
                  <FormControl id="name" isRequired isInvalid={errors?.name}>
                    <FormLabel>Nimi</FormLabel>
                    <InputGroup>
                      <Input
                        id="name"
                        placeholder="Nimi"
                        isRequired
                        onChange={handleInputChange}
                        isInvalid={errors?.name}
                        value={inputs?.name || ""}
                      />
                    </InputGroup>
                    <FormErrorMessage>{errors?.name?.msg}</FormErrorMessage>
                  </FormControl>
                  <Box my="1rem" />
                  <Stack direction="row">
                    <FormControl id="email" isInvalid={errors?.email}>
                      <FormLabel>E-maili aadress</FormLabel>
                      <InputGroup>
                        <Input
                          id="email"
                          type="email"
                          placeholder="E-maili aadress"
                          onChange={handleInputChange}
                          isInvalid={errors?.email}
                          value={inputs?.email || ""}
                        />
                      </InputGroup>
                      <FormErrorMessage>{errors?.email?.msg}</FormErrorMessage>
                    </FormControl>
                    <FormControl id="phone" isInvalid={errors?.phone}>
                      <FormLabel>Telefon</FormLabel>
                      <InputGroup>
                        <Input
                          id="phone"
                          type="phone"
                          placeholder="Telefoni number"
                          onChange={handleInputChange}
                          isInvalid={errors?.phone}
                          value={inputs?.phone || ""}
                        />
                      </InputGroup>
                      <FormErrorMessage>{errors?.phone?.msg}</FormErrorMessage>
                    </FormControl>
                  </Stack>
                  <Box my="1rem" />
                  <Stack direction="row">
                    <FormControl id="product">
                      <FormLabel>Toode</FormLabel>
                      <InputGroup>
                        {/*  <CreatableSelect
                          onChange={(e) =>
                            handleSingleValueChange(e.value, "product")
                          }
                          value={productOptions.find(
                            (prod) => prod.value === inputs?.product
                          )}
                          options={productOptions}
                        /> */}
                        <Textarea
                          value={inputs?.product || ""}
                          onChange={handleInputChange}
                          placeholder="Sisu"
                        />
                      </InputGroup>
                    </FormControl>
                    {/*                     <FormControl width="125px" id="quantity" isRequired>
                      <FormLabel>Kogus</FormLabel>
                      <InputGroup>
                        <NumberInput
                          id="quantity"
                          name="quantity"
                          min={1}
                          max={100}
                          onChange={(val) =>
                            handleSingleValueChange(+val, "quantity")
                          }
                          value={inputs?.quantity}
                          isInvalid={errors?.quantity || ""}
                        >
                          <NumberInputField placeholder="0" />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </InputGroup>
                    </FormControl> */}
                  </Stack>
                  <Box my="1rem" />
                </>
              )}
            </DrawerBody>
            <DrawerFooter>
              <Button
                variant="outline"
                mr={3}
                onClick={handleDrawerClose}
                isDisabled={isSubmitting}
              >
                Katkesta
              </Button>
              <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
                Salvesta
              </Button>
            </DrawerFooter>
          </form>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default BookingCreator;
